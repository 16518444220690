import React from 'react'
import './project-detail-container.scss'
import { H2, Flex, Image } from '../../lib/components'
import { CLIENT_ENGAGEMENTS } from '../../lib/utils/message'

const ClientEngagements = ({
  clientEngagement1,
  clientEngagement2,
  clientEngagement3,
  clientEngagement4,
}: {
  clientEngagement1: string
  clientEngagement2: string
  clientEngagement3: string
  clientEngagement4: string
}) => {
  return (
    <Flex variant='column' classes={['mt-60', 'client-engagement']}>
      <H2 text={CLIENT_ENGAGEMENTS} />
      <Flex variant='space-between' classes={['mt-60']}>
        {clientEngagement1 && (
          <Image
            source={clientEngagement1}
            classes={['client-engagement-images']}
            radius='r8'
            variant='all'
          />
        )}
        {clientEngagement2 && (
          <Image
            source={clientEngagement2}
            classes={['client-engagement-images']}
            radius='r8'
            variant='all'
          />
        )}
      </Flex>
      <Flex variant='space-between' classes={['mt-60']}>
        {clientEngagement3 && (
          <Image
            source={clientEngagement3}
            classes={['client-engagement-images']}
            radius='r8'
            variant='all'
          />
        )}
        {clientEngagement4 && (
          <Image
            source={clientEngagement4}
            classes={['client-engagement-images']}
            radius='r8'
            variant='all'
          />
        )}
      </Flex>
    </Flex>
  )
}

export { ClientEngagements }
