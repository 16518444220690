import { axiosInstance } from '.'
import { JobResponse } from '../lib/types'
import { filterJobFromJobResp } from '../lib/utils/helpers'
import { GET_JOB_LIST } from './endpoints'

export const jobServices = {
  getJobs: async (params: any, headers: { [key: string]: string }) => {
    try {
      const response = await axiosInstance.get(GET_JOB_LIST, {
        params: params,
        headers: headers,
      })
      const data: JobResponse[] = response.data
      const filtered = filterJobFromJobResp(data)
      return {
        status: response.status,
        jobs: filtered,
      }
    } catch (error: any) {
      return error.response
    }
  },
}
