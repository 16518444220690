/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Button, Flex, H2, Input, SelectDropdown, Span, ToolTip } from '../../lib/components'
import { Pagination } from '../../lib/components/Pagination'
import { Queries, SearchParams } from '../../lib/types'
import { SM } from '../../lib/utils/constants'
import { trimText } from '../../lib/utils/helpers'
import { useBreakpoint } from '../../lib/utils/hooks'
import { queriesFilter, queriesSort } from '../../lib/utils/message'
import { queriesServices } from '../../services/queries-services'
import { RootState } from '../../store'
import './queries-list.scss'

const QueriesList = () => {
  const navigate = useNavigate()
  const { breakpoint } = useBreakpoint()
  const { authToken } = useSelector((state: RootState) => state.gbcUser)
  const [queries, setQueries] = useState<Queries[]>()
  const [searchParams, setSearchParams] = useState<SearchParams>({
    limit: '10',
    offset: '0',
  })
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    count: 5,
    offset: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    fetchQueriesList()
  }, [currentPage, searchParams])

  const fetchQueriesList = async () => {
    const response: any = await queriesServices.searchQueries(searchParams, {
      Authorization: authToken,
    })
    if (response && response.data && response.status === 200) {
      setQueries(response.data)
      setPagination(response.pagination)
    }
  }

  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    const { limit, offset } = pagination
    setSearchParams({
      ...searchParams,
      offset: '0',
      limit: limit.toString(),
      [name]: value,
    })
    setCurrentPage(1)
    setPagination({ ...pagination, offset: 0 })
  }

  const resetSearchParams = () => {
    setSearchParams({
      limit: '10',
      offset: '0',
      keyword: '',
      filterBy: '',
      sortBy: '',
    })
  }

  const handleCurrentPage = (currentPage: number) => {
    const { limit } = pagination
    setCurrentPage(currentPage)
    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })
    setPagination({ ...pagination, offset: (currentPage - 1) * limit })
  }

  return (
    <Flex variant='column-center'>
      <H2 classes={['mb-48']} text='Inquiries' />
      <div className='toolbar-layout gap-12 mb-16 mt-16'>
        <Input
          name='keyword'
          value={searchParams.keyword || ''}
          handleChange={(e) => handleSearchParams({ name: e.target.name, value: e.target.value })}
          placeholder='Search'
        />
        <SelectDropdown
          name='filterBy'
          id='filterId'
          formId=''
          list={queriesFilter}
          value={searchParams.filterBy}
          placeholder={'Filter'}
          handleChange={(val) => handleSearchParams({ name: 'filterBy', value: val })}
        />
        <div className='sort-reset'>
          <SelectDropdown
            name='sortBy'
            id='sortById'
            formId=''
            list={queriesSort}
            value={searchParams.sortBy}
            placeholder={'Sort'}
            handleChange={(val) => handleSearchParams({ name: 'sortBy', value: val })}
          />
          <Button
            handleClick={() => resetSearchParams()}
            classes={['reset-btn']}
            variant='secondary-btn'
            text='Reset'
          />
        </div>
      </div>
      {queries && queries.length ? (
        <div className='w-100'>
          <Flex variant='row' classes={['mb-24', 'table_scroll_horizontal']}>
            <table className='styled-table'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Subject</th>
                  <th>Message</th>
                  <th>Received on</th>
                  <th>Category (Investors)</th>
                </tr>
              </thead>
              <tbody>
                {queries.map((query) => {
                  return (
                    <tr key={uuidv4()}>
                      <td>{query.name}</td>
                      <td>{query.email}</td>
                      <td>{query.subject}</td>
                      <td>
                        {
                          <ToolTip content={query.message}>
                            <span>{trimText(query.message)}</span>
                          </ToolTip>
                        }
                      </td>
                      <td>{new Date(query.created_at).toLocaleString()}</td>
                      <td>{query.assistance_category}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Flex>
          {breakpoint && breakpoint === SM
            ? null
            : queries &&
              queries.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  handleCurrentPage={handleCurrentPage}
                  pagination={pagination}
                />
              )}
        </div>
      ) : (
        <Flex variant={'column-center'} classes={['mt-12']}>
          <Span classes={['center']} variant={'thin'} text='No Inquiry Found' />
        </Flex>
      )}
    </Flex>
  )
}

export { QueriesList }
