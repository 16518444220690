/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { H2, LockResetIcon, Span, Button, Input } from '../../lib/components'
import { IFormProps } from '../../lib/types'
import { FormikProps, Formik } from 'formik'
import {
  FORGOT_PASSWORD_TEXT01,
  FORGOT_PASSWORD_TEXT02,
  FORGOT_PASSWORD,
  CONTINUE,
} from '../../lib/utils/message'

const ForgotPasswordPresenter = ({
  initialValues,
  handleFormSubmit,
  validationSchema,
}: IFormProps<{ email: string }, { email: string }>) => {
  return (
    <>
      <LockResetIcon />
      <H2 classes={['mt-32 mb-32']} text={FORGOT_PASSWORD} />
      <Span text={FORGOT_PASSWORD_TEXT01} variant='default' />
      <Span text={FORGOT_PASSWORD_TEXT02} variant='default' />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(formik) => {
          const {
            values,
            touched,
            errors,
            dirty,
            handleChange,
            handleSubmit,
          }: FormikProps<typeof initialValues> = formik
          return (
            <form className='w-80 mt-32'>
              <Input
                name='email'
                placeholder='Email'
                type='text'
                required
                value={values.email}
                handleChange={handleChange(initialValues.email)}
                dirty={dirty}
                errors={{
                  email: !!errors && errors.email ? errors.email : null,
                }}
                touched={{ email: !!touched && !!touched.email }}
              />
              <Button
                classes={['mt-13']}
                variant={'primary-btn'}
                text={CONTINUE}
                handleClick={handleSubmit}
              />
            </form>
          )
        }}
      </Formik>
    </>
  )
}

export { ForgotPasswordPresenter }
