import React from 'react'
import { Button, Flex, H2, Input, Span, SelectDropdown, Line, Toggle } from '../../lib/components'
import { OpportunityCard } from '../../lib/components/OpportunityCard'
import { Pagination } from '../../lib/components/Pagination'
import './opportunity-list.scss'
import { useBreakpoint } from '../../lib/utils/hooks'
import { SM } from '../../lib/utils/constants'
import { Opportunity } from '../../lib/types'
import {
  calculateCommittedPercentage,
  displayDollar,
  displayPercentage,
  calculateRemainingDays,
} from '../../lib/utils/helpers'
import { projectFilters, projectSort } from '../../lib/utils/message'

function OpportunityListPresenter({
  opportunities,
  searchParams,
  handleSearchParams,
  resetSearchParams,
  handleProjectClick,
  pagination,
  handleCurrentPage,
  currentPage,
  handleProjectInvestmentClick,
  isCloseStatusToggle,
  setIsCloseStatusToggle,
}: any) {
  const { breakpoint } = useBreakpoint()
  return (
    <Flex variant='column-center'>
      <H2 classes={['mb-48']} text='Browse Opportunities' />
      <div className='toolbar-layout gap-12 mb-16 mt-16'>
        <Input
          name='keyword'
          value={searchParams.keyword}
          handleChange={(e) => handleSearchParams({ name: e.target.name, value: e.target.value })}
          placeholder='Search'
        />
        <SelectDropdown
          name='filterBy'
          id='filterId'
          formId=''
          list={projectFilters}
          value={searchParams.filterBy}
          placeholder={'Filter'}
          handleChange={(val) => handleSearchParams({ name: 'filterBy', value: val })}
        />
        <div className='sort-reset'>
          <SelectDropdown
            name='sortBy'
            id='sortById'
            formId=''
            list={projectSort}
            placeholder={'Sort'}
            value={searchParams.sortBy}
            handleChange={(val) => handleSearchParams({ name: 'sortBy', value: val })}
          />
          <Button
            handleClick={() => resetSearchParams()}
            classes={['reset-btn']}
            variant='secondary-btn'
            text='Reset'
          />
        </div>
      </div>
      <Flex classes={['w-100']} variant='column'>
        <div className='input-label mb-8'>
          {' '}
          {isCloseStatusToggle ? 'Active Opportunities' : 'Closed Opportunities'}{' '}
        </div>
        <Toggle
          checked={isCloseStatusToggle}
          handleChange={() => {
            setIsCloseStatusToggle((prevState: boolean) => !prevState)
          }}
        />
      </Flex>
      <Line />
      {opportunities && opportunities.length > 0 ? (
        <>
          <div className='w-100'>
            <Flex variant='row' classes={['mb-24', 'table_scroll_horizontal']}>
              <table className={isCloseStatusToggle ? 'styled-table' : 'no-styled-table'}>
                <thead>
                  <tr>
                    <th>Opportunity Name</th>
                    <th>Asset Class</th>
                    <th>Expected Return</th>
                    <th>Ticket Size</th>
                    <th>Committed %</th>
                    <th>Closing Status</th>
                    <th>Publish Status</th>
                    <th>Investments</th>
                    <th>Edit</th>
                    {/* <th>Delete</th> */}
                  </tr>
                </thead>
                <tbody>
                  {opportunities &&
                    opportunities.length > 0 &&
                    (isCloseStatusToggle
                      ? opportunities
                          .filter((obj: any) => {
                            const closeDays =
                              obj.closing_date &&
                              calculateRemainingDays(new Date(), new Date(obj.closing_date))
                            if (closeDays > 0) {
                              return obj
                            }
                          })
                          .map(
                            ({
                              id,
                              opportunity_title,
                              opportunity_slug,
                              investment_return,
                              asset_class,
                              closing_date,
                              currency_code,
                              ticket_size,
                              raise_goal,
                              total_investment_amount,
                              status,
                            }: Opportunity) => (
                              <OpportunityCard
                                key={id}
                                id={id}
                                opportunitySlug={opportunity_slug}
                                closingDate={closing_date}
                                opportunityTitle={opportunity_title}
                                isPublished={status === 'PUBLISHED'}
                                committedPercentage={
                                  total_investment_amount
                                    ? calculateCommittedPercentage(
                                        total_investment_amount.toString(),
                                        raise_goal.toString(),
                                      )
                                    : '0%'
                                }
                                expectedReturn={displayPercentage(investment_return.toString())}
                                assetClass={asset_class}
                                ticketSize={displayDollar(ticket_size.toString(), currency_code)}
                                handleClick={handleProjectClick}
                                handleProjectInvestmentClick={handleProjectInvestmentClick}
                              />
                            ),
                          )
                      : opportunities
                          .filter((obj: any) => {
                            const closeDays =
                              obj.closingDate &&
                              calculateRemainingDays(new Date(), new Date(obj.closingDate))
                            if (closeDays <= 0) {
                              return obj
                            }
                          })
                          .map(
                            ({
                              id,
                              opportunity_title,
                              opportunity_slug,
                              investment_return,
                              asset_class,
                              closing_date,
                              currency_code,
                              ticket_size,
                              raise_goal,
                              total_investment_amount,
                              status,
                            }: Opportunity) => (
                              <OpportunityCard
                                key={id}
                                id={id}
                                opportunitySlug={opportunity_slug}
                                closingDate={closing_date}
                                opportunityTitle={opportunity_title}
                                isPublished={status === 'PUBLISHED'}
                                committedPercentage={
                                  total_investment_amount
                                    ? calculateCommittedPercentage(
                                        total_investment_amount.toString(),
                                        raise_goal.toString(),
                                      )
                                    : '0%'
                                }
                                expectedReturn={displayPercentage(investment_return.toString())}
                                assetClass={asset_class}
                                ticketSize={displayDollar(ticket_size.toString(), currency_code)}
                                handleClick={handleProjectClick}
                                handleProjectInvestmentClick={handleProjectInvestmentClick}
                              />
                            ),
                          ))}
                </tbody>
              </table>
            </Flex>
          </div>
          {breakpoint && breakpoint === SM ? null : (
            <Pagination
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
              pagination={pagination}
            />
          )}
        </>
      ) : (
        <Flex variant={'column-center'} classes={['mt-12']}>
          <Span classes={['center']} variant={'thin'} text='No Opportunities Found' />
        </Flex>
      )}
    </Flex>
  )
}

export { OpportunityListPresenter }
