import React from 'react'
import './flex-component.scss'
import { FlexProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

const Flex = ({ children, variant = 'row', classes }: FlexProps) => {
  return <>{<div className={`flex ${variant} ${concatClassName(classes)}`}> {children} </div>}</>
}
export { Flex }

const FlexCenter = ({ children, classes }: FlexProps) => {
  const classNames = classes && [...classes, 'vh-50']
  return (
    <Flex variant='row-center' classes={classNames}>
      <Flex variant='column-center'>{children}</Flex>
    </Flex>
  )
}
export { FlexCenter }
