import { gbcUserState } from '../types/propsTypes'
export const setGbcUserDataToLS = (gbcUserData: gbcUserState) => {
  localStorage.setItem('authToken', gbcUserData.authToken)
  localStorage.setItem(
    'gbc_user_data',
    JSON.stringify({
      email: gbcUserData.email,
      userId: gbcUserData.userId,
      role: gbcUserData.role,
      isEmailVerified: gbcUserData.isEmailVerified,
    }),
  )
}

export const removeGbcUserDataFromLS = () => {
  localStorage.removeItem('authToken')
  localStorage.removeItem('gbc_user_data')
}

export const getGbcUserDataFromLS = () => {
  const gbcUserData = localStorage.getItem('gbc_user_data')
  const authToken = localStorage.getItem('authToken')
  return {
    authToken,
    gbcUserData: gbcUserData ? JSON.parse(gbcUserData) : null,
  }
}
