import React from 'react'
import './list-component.scss'
import { ListProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

const List = ({ listData, classes }: ListProps) => {
  const listItems =
    listData &&
    listData.map((data, index) => {
      return (
        <li className='list-item' key={index}>
          {' '}
          {data}{' '}
        </li>
      )
    })
  return <ul className={`list ${concatClassName(classes)}`}> {listItems} </ul>
}
export { List }
