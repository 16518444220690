import { homeDetails } from '../../lib/types'

export const homeInitialValues: homeDetails = {
  keyInvestorsImg1: '',
  keyInvestorsImg2: '',
  keyInvestorsImg3: '',
  keyInvestorsImg4: '',
  keyInvestorsImg5: '',
  keyInvestorsName1: '',
  keyInvestorsName2: '',
  keyInvestorsName3: '',
  keyInvestorsName4: '',
  keyInvestorsName5: '',
}
