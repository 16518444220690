/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { H2, DeniedIcon, P, Button, FlexCenter } from '../../lib/components'
import './accessdenied.scss'

const AccessDenied = () => {
  const navigate = useNavigate()

  return (
    <FlexCenter variant={'column-center'} classes={['mb-24']}>
      <div className='bounce-amimation bounce-1'>
        <DeniedIcon />
      </div>
      <H2 classes={['mb-32 mt-32']} text={'Whoops!'} />
      <P
        text={'You are not authorised to access this resource.Please contact the administrator'}
        classes={['center']}
      />
      <Button
        classes={['auto-btn-width', 'mt-36']}
        variant={'primary-btn'}
        text={'Go Home'}
        handleClick={() => {
          navigate('/')
        }}
      />
    </FlexCenter>
  )
}

export { AccessDenied }
