export type AssetsClass = 'Trade Finance' | 'Fixed Income' | 'Private Equity' | 'Mutual Fund'
export type PublishingStatus = 'PUBLISHED' | 'UNPUBLISHED'

export const assetsClass: AssetsClass[] = [
  'Fixed Income',
  'Private Equity',
  'Trade Finance',
  'Mutual Fund',
]
export const publishedStatus: PublishingStatus[] = ['PUBLISHED', 'UNPUBLISHED']
