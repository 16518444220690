/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { H2, DeniedIcon, P, Button, Flex, FlexCenter } from '../../lib/components'
import './notFoundPage.scss'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <FlexCenter variant={'column-center'} classes={['mt-0']}>
      <div className='bounce-amimation bounce-1'>
        <DeniedIcon />
      </div>
      <H2 classes={['mb-32']} text={'Whoops!'} />
      <P text={'Seems like we could not find the page you were looking for'} classes={['center']} />
      <Button
        classes={['auto-btn-width', 'mt-36']}
        variant={'primary-btn'}
        text={'Login'}
        handleClick={() => {
          navigate('/login')
        }}
      />
    </FlexCenter>
  )
}

export { NotFound }
