import { axiosInstance } from '.'
import {
  SEND_VERIFICATION_LINK,
  RESET_PASSWORD,
  USER_SIGN_IN_V1,
  USER_LOGOUT_V1,
  SEND_VERIFICATION_LINK_V1,
} from './endpoints'
import { User, Verification } from '../lib/types'

export const userServices = {
  userLogin: async (loginData: User, headers: { [key: string]: string }) => {
    try {
      // return await axiosInstance.post(USER_SIGN_IN, loginData, {
      //     headers: headers
      // })
      return await axiosInstance.post(
        USER_SIGN_IN_V1,
        {
          ...loginData,
          //   role: 'GBC_USER',
        },
        {
          headers: headers,
        },
      )
    } catch (error: any) {
      return error.response
    }
  },
  sendVerificationLink: async (email: string, type: Verification) => {
    try {
      // return await axiosInstance.post(SEND_VERIFICATION_LINK, { email, type });
      return await axiosInstance.post(SEND_VERIFICATION_LINK_V1, {
        email,
        type,
      })
    } catch (error: any) {
      return error.response
    }
  },
  resetPassword: async (resetPasswordData: object, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(RESET_PASSWORD, resetPasswordData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
  userLogout: async (headers: { [key: string]: string }) => {
    try {
      //   return await axiosInstance.post(USER_LOGOUT, {}, { headers: headers });
      return await axiosInstance.post(USER_LOGOUT_V1, {}, { headers: headers })
    } catch (error: any) {
      return error.response
    }
  },
}
