/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import {
  Button,
  Close,
  DownloadIcon,
  EyeIcon,
  Flex,
  H4,
  Input,
  InputModal,
  Line,
  SelectDropdown,
  Span,
  ToolTip,
} from '../../lib/components'
import { KYCDocuments } from '../../lib/types'
import { trimText } from '../../lib/utils/helpers'
import { documentStatus } from '../../lib/utils/message'

const InvestorKYC = ({
  documents,
  changeHandler,
  saveCommentOrApprove,
  handleFilePreview,
  allDocuments,
  pendingDocuments,
}: KYCDocuments) => {
  const [displayModal, setDisplayModal] = useState(false)
  const [documentData, setDocumentData] = useState<any>()
  const handleCloseModal = () => {
    setDisplayModal((state) => !state)
  }
  return (
    <>
      {documents && documents.length > 0 ? (
        <Flex variant='row' classes={['mb-24', 'table_scroll_horizontal']}>
          {displayModal ? (
            <InputModal>
              <Flex variant='column' classes={['input-modal']}>
                <Flex variant='space-between'>
                  <H4 text={'Add Comment'} />
                  <Close height='24px' onClick={() => handleCloseModal()} />
                </Flex>
                <Line />
                <Flex variant='column' classes={['reply-section']}>
                  <Input
                    placeholder='Limit is 200 Characters'
                    name='comment'
                    type={'text'}
                    maxLength={200}
                    required
                    handleChange={(e) =>
                      documentData &&
                      setDocumentData({
                        ...documentData,
                        comment: e.target.value,
                      })
                    }
                    value={documentData && documentData.comment ? documentData.comment : ''}
                    infoText={`Character ${
                      documentData && documentData.comment ? documentData.comment.length : '0'
                    } out of 200`}
                  />
                  <Button
                    classes={['mt-24']}
                    variant='primary-btn'
                    text='Comment'
                    handleClick={() => {
                      documentData &&
                        saveCommentOrApprove(documentData.id, documentData.comment, 'comment')
                      handleCloseModal()
                    }}
                  />
                </Flex>
              </Flex>
            </InputModal>
          ) : null}
          <table className='styled-table'>
            <thead>
              <tr>
                <th>Document Name</th>
                <th>Uploaded on</th>
                <th>Status</th>
                <th>Comment</th>
                <th>Reply</th>
                <th>Preview</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc) => {
                return (
                  !!doc.id && (
                    <tr key={doc.id}>
                      <td>{allDocuments[doc.name]}</td>
                      <td>{`${new Date(doc.createdAt).getDate()}/${
                        new Date(doc.createdAt).getMonth() + 1
                      }/${new Date(doc.createdAt).getFullYear()}`}</td>
                      <td>
                        <SelectDropdown
                          name='documentStatus'
                          id='documentStatusId'
                          formId=''
                          list={documentStatus}
                          value={doc.status}
                          handleChange={(val) => {
                            if (val) {
                              changeHandler(doc.id, val, 'status')
                              saveCommentOrApprove(doc.id, val, 'status')
                            }
                          }}
                        />
                      </td>
                      <td>
                        {doc.comment ? (
                          <ToolTip content={doc.comment}>
                            <span
                              className='pointer'
                              onClick={() => {
                                setDocumentData(doc)
                                handleCloseModal()
                              }}
                            >
                              {trimText(doc.comment)}
                            </span>
                          </ToolTip>
                        ) : (
                          <Button
                            variant='secondary-btn'
                            classes={['success-stories-expand-button']}
                            text='+'
                            handleClick={() => {
                              setDocumentData(doc)
                              handleCloseModal()
                            }}
                          />
                        )}
                      </td>
                      <td>
                        <ToolTip content={doc.reply}>
                          <span>{trimText(doc.reply)}</span>
                        </ToolTip>
                      </td>
                      <td>
                        <EyeIcon
                          classes={['align-center', 'pointer']}
                          height='24px'
                          width='24px'
                          onClick={async () => {
                            const url = await handleFilePreview(doc.file)
                            window.open(url, '_blank')
                          }}
                        />
                      </td>
                      <td>
                        <a href={doc.file} target='_blank' rel='noopener noreferrer' download>
                          <DownloadIcon
                            classes={['align-center', 'pointer']}
                            height='24px'
                            width='20px'
                          />
                        </a>
                      </td>
                    </tr>
                  )
                )
              })}
            </tbody>
          </table>
        </Flex>
      ) : (
        <Flex variant={'column'}>
          <Span classes={['mb-32', 'mt-8']} variant={'thin'} text='No Documents Uploaded' />
        </Flex>
      )}
      <H4 text='Pending documents from the investor' />
      {pendingDocuments && pendingDocuments.length > 0 ? (
        <table className='no-styled-table'>
          <thead>
            <tr>
              <th>Document Name</th>
              <th>Uploaded on</th>
            </tr>
          </thead>
          <tbody>
            {pendingDocuments.map((name) => {
              return (
                <tr key={name}>
                  <td>{allDocuments[name]} </td>
                  <td>{'Not uploaded yet'} </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <Flex variant={'column'}>
          <Span classes={['mb-32', 'mt-8']} variant={'thin'} text='No Pending Documents' />
        </Flex>
      )}
    </>
  )
}

export { InvestorKYC }
