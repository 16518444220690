/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import './project-detail-container.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { ProjectDetailPresenter } from './project-detail-presenter'
import { projectServices } from '../../services'
import { ProjectCardProps } from '../../lib/types'

const ProjectDetailContainer = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id, projectSlug } = params
  const { authToken } = useSelector((state: RootState) => state.gbcUser)
  const [project, setProject] = useState<ProjectCardProps>()
  useEffect(() => {
    const fetchProjectById = async () => {
      const data: any = await projectServices.getOpportunityById(id as string, false)
      if (data && data.data && data.status === 200) {
        setProject(data.data)
      }
    }
    if (id !== 'undefined' && projectSlug !== 'undefined') fetchProjectById()
    else navigate('/')
  }, [])
  return (
    <>
      {project && (
        <ProjectDetailPresenter
          project={project}
          investHandleClick={() => {
            console.log('invest handler')
          }}
          viewDocumentHandleClick={(legalDocument: string) => {
            window.open(legalDocument, '_blank')
          }}
        />
      )}
    </>
  )
}

export { ProjectDetailContainer }
