import React, { useState, useEffect } from 'react'
import { HomeFormPresenter } from './home-form-presenter'
import { homeSchema } from '../../lib/utils/schema'
import { homeDetails } from '../../lib/types'
import { homeServices } from '../../services'
import { useDispatch } from 'react-redux'
import { showBanner } from '../../global-state/banner-slice'
import { useNavigate } from 'react-router-dom'
import { homeInitialValues } from './home-initialValues'
import { isValidFileSize, isValidImageType, isValidDocType } from '../../lib/utils/helpers'

const HomeFormContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState<homeDetails>(homeInitialValues)

  useEffect(() => {
    const fetchProjectById = async () => {
      const data: any = await homeServices.getHomeRequest()
      if (data && data.data && data.status === 200) {
        setInitialValues(data.data)
      }
    }
    fetchProjectById()
  }, [])

  const handleSubmit = async (home: homeDetails) => {
    const result = {
      ...(initialValues.keyInvestorsImg1 !== home.keyInvestorsImg1 && {
        keyInvestorsImg1: home.keyInvestorsImg1,
      }),
      ...(initialValues.keyInvestorsImg2 !== home.keyInvestorsImg2 && {
        keyInvestorsImg2: home.keyInvestorsImg2,
      }),
      ...(initialValues.keyInvestorsImg3 !== home.keyInvestorsImg3 && {
        keyInvestorsImg3: home.keyInvestorsImg3,
      }),
      ...(initialValues.keyInvestorsImg4 !== home.keyInvestorsImg4 && {
        keyInvestorsImg4: home.keyInvestorsImg4,
      }),
      ...(initialValues.keyInvestorsImg5 !== home.keyInvestorsImg5 && {
        keyInvestorsImg5: home.keyInvestorsImg5,
      }),
      ...(initialValues.keyInvestorsName1 !== home.keyInvestorsName1 && {
        keyInvestorsName1: home.keyInvestorsName1,
      }),
      ...(initialValues.keyInvestorsName2 !== home.keyInvestorsName2 && {
        keyInvestorsName2: home.keyInvestorsName2,
      }),
      ...(initialValues.keyInvestorsName3 !== home.keyInvestorsName3 && {
        keyInvestorsName3: home.keyInvestorsName3,
      }),
      ...(initialValues.keyInvestorsName4 !== home.keyInvestorsName4 && {
        keyInvestorsName4: home.keyInvestorsName4,
      }),
      ...(initialValues.keyInvestorsName5 !== home.keyInvestorsName5 && {
        keyInvestorsName5: home.keyInvestorsName5,
      }),
    }
    const homeData: any = await homeServices.updateHomeRequest(result)
    if (!!homeData && homeData.status === 200) {
      dispatch(
        showBanner({
          text: 'Home updated successfully',
          variant: 'success-banner',
        }),
      )
      navigate('/home')
    } else {
      dispatch(
        showBanner({
          text:
            (homeData.data && homeData.data.message) || 'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
    }
  }

  const handleFileUpload = async (fileData: File, fileType: 'image' | 'doc' | 'video') => {
    const isValidImage =
      fileType === 'image' ? isValidImageType(fileData) : isValidDocType(fileData)
    const isValidSize = isValidFileSize(fileData, fileType)
    if (isValidImage && isValidSize) {
      const data: any = await homeServices.postUploadFiles(fileData)
      if (data) return data
    } else {
      dispatch(
        showBanner({
          text: 'File size or type is incorrect for field',
          variant: 'error-banner',
        }),
      )
    }
  }
  const handleErrorMessage = (val: string) =>
    dispatch(showBanner({ text: val, variant: 'error-banner' }))

  return (
    <HomeFormPresenter
      initialValues={initialValues}
      validationSchema={homeSchema}
      handleFormSubmit={handleSubmit}
      handleFileUpload={handleFileUpload}
      handleErrorMessage={handleErrorMessage}
    />
  )
}

export { HomeFormContainer }
