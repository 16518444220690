export const XS = 0
export const SM = 576
export const MD = 768
export const LG = 992
export const XL = 1200
export const XXL = 1400

export const breakpoints = {
  0: 'xs',
  576: 'sm',
  768: 'md',
  992: 'lg',
  1200: 'xl',
  1400: 'xxl',
}

// Asset Classes
export const ASSET_CLASSES = {
  PRIVATE_EQUITY: 'Private Equity',
  REAL_ESTATE: 'Real Estate',
  FUNDS_OF_FUNDS: 'Funds of Funds',
  FIXED_INCOME: 'Fixed Income',
  LISTED_EQUITIES: 'Listed Equities',
}
