import { axiosInstance } from '.'
import { homeDetails } from '../lib/types'
import { UPLOAD_FILE, HOME, HOME_UPDATE } from './endpoints'

export const homeServices = {
  updateHomeRequest: async (homeData: any) => {
    try {
      const data: any = await axiosInstance.post(HOME_UPDATE, homeData)
      const project = { ...data.data }
      return { data: project, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },
  postUploadFiles: async (filesData: File) => {
    try {
      const formData = new FormData()
      formData.append('file', filesData)
      return await axiosInstance.post(UPLOAD_FILE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (error: any) {
      return error.response
    }
  },
  getHomeRequest: async () => {
    try {
      const data = await axiosInstance.get(HOME)
      return { data: data.data, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },
}
