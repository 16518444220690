export const entities = [
  { text: 'Individual', value: 'Individual' },
  { text: 'LLP', value: 'LLP' },
  { text: 'Corporate Entity', value: 'CorporateEntity' },
  { text: 'Investment Fund', value: 'InvestmentFund' },
  { text: 'Trusts', value: 'Trusts' },
  { text: 'Private Foundations', value: 'PrivateFoundations' },
]

export const kycMap: { [key: string]: any } = {
  Individual: {
    curriculamVitae: 'Curriculam Vitae',
    documentEvidencingSourceOfFundWealth: 'Document evidencing source of fund & wealth',
    personalIdentityCertificate: 'Personal Identity Certificate',
    addressVerification: 'Address Verification',
  },
  LLP: {
    curriculamVitae: 'Curriculam Vitae',
    documentEvidencingSourceOfFundWealth: 'Document evidencing source of fund & wealth',
    profileOfTheCorporation: 'Profile of the Corporation',
    partnershipAgreementOrLlcOperatingAgreement: 'Partnership agreement or LLC operating agreement',
    lpOrLlcMandateAuthorizingTheEstablishmentOfTheRelationship:
      'LP or LLC mandate authorizing the establishment of the relationship',
    conferringAuthorityOnThoseWhoIssueInstructions:
      'Conferring authority on those who issue instructions',
    registeredAddressVerificationOfCorporation: 'Registered address verification of corporation',
  },
  CorporateEntity: {
    curriculamVitae: 'Curriculam Vitae',
    documentEvidencingSourceOfFundWealth: 'Document evidencing source of fund & wealth',
    profileOfTheCorporation: 'Profile of the Corporation',
    authorizedSignatoryList: 'Authorized signatory list',
    listOfDirectorsNames: 'List of directors names',
    certificateOfIncorporationOrSimilarDocument: 'Certificate of incorporation or similar document',
    personalIdentityAndAddressVerificationOfAllDirectors:
      'Personal Identity and Address Verification of all directors',
    listOfNamesAndAddressesOfShareholdersHolding10OrMore:
      'List of names and addresses of shareholders holding 10% or more',
  },
  InvestmentFund: {
    curriculamVitae: 'Curriculam Vitae',
    documentEvidencingSourceOfFundWealth: 'Document evidencing source of fund & wealth',
    profileOfTheCorporation: 'Profile of the Corporation',
    authorizedSignatoryList: 'Authorized signatory list',
    listOfDirectorsNames: 'List of directors names',
    certificateOfIncorporationOrSimilarDocument: 'Certificate of incorporation or similar document',
    personalIdentityAndAddressVerificationOfAllDirectors:
      'Personal Identity and Address Verification of all directors',
    writtenConfirmationByTheAdministratorOfTheFund:
      'Written confirmation by the administrator of the fund',
  },

  Trusts: {
    curriculamVitae: 'Curriculam Vitae',
    documentEvidencingSourceOfFundWealth: 'Document evidencing source of fund & wealth',
    profileOfTheCorporation: 'Profile of the Corporation',
    documentaryEvidenceShowingThatTheTrusteesIsApprovedFinancialInstitution:
      'Documentary evidence showing that the trustees is approved Financial Institution',
    documentaryEvidenceShowingThatTheTrusteesIsApprovedNonFinancialInstitution:
      'Documentary evidence showing that the trustees is approved NON Financial Institution',
    authorizedSignatoryList: 'Authorized signatory list',
    listOfDirectorsNames: 'List of directors names',
    personalIdentityAndAddressVerificationOfAllDirectors:
      'Personal Identity and Address Verification of all directors',
    aCertifiedTrueCopyOfTheTrustDeed: 'A certified true copy of the Trust Deed',
  },

  PrivateFoundations: {
    curriculamVitae: 'Curriculam Vitae',
    documentEvidencingSourceOfFundWealth: 'Document evidencing source of fund & wealth',
    profileOfTheCorporation: 'Profile of the Corporation',
    authorizedSignatoryList: 'Authorized signatory list',
    listOfDirectorsNames: 'List of directors names',
    personalIdentityAndAddressVerificationOfAllDirectors:
      'Personal Identity and Address Verification of all directors',
    personalIdentityAndAddressVerificationOfTheFounder:
      'Personal Identity and Address Verification of the founder',
    certificateOfIncorporationOrSimilarDocument: 'Certificate of incorporation or similar document',
  },
}
