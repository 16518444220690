import * as yup from 'yup'
import * as _ from './message'
import { passwordRegex, isValidFileSize, isValidImageType } from './helpers'
import { assetsClass, publishedStatus } from '../types/opportunityTypes'
import { maxHeaderSize } from 'http'

const signInSchema = yup.object().shape({
  email: yup.string().required(_.EMAIL_REQUIRED_ERROR).email(_.EMAIL_ERROR),
  password: yup
    .string()
    .required(_.PASSWORD_REQUIRED_ERROR)
    .matches(passwordRegex, _.PASSWORD_ERROR),
})

const forgotPasswordSchema = yup.object({
  password: yup
    .string()
    .required(_.PASSWORD_REQUIRED_ERROR)
    .matches(passwordRegex, _.PASSWORD_ERROR),
  passwordConfirmation: yup
    .string()
    .required(_.CONFIRM_PASSOWRD_REQUIRED_ERROR)
    .oneOf([yup.ref('password'), null], _.CONFIRM_PASSOWRD_ERROR),
})
const emailInputSchema = yup.object({
  email: yup.string().required(_.EMAIL_REQUIRED_ERROR).email(_.EMAIL_ERROR),
})

const projectSchema = yup.object({
  projectName: yup.string().max(70).required(_.isRequiredMessage('Opportunity Name')),
  projectSlug: yup.string(),
  valuation: yup.number().positive().min(0),
  dealContours: yup.string(),
  companyMilestones: yup.string(),
  clientEngagement1: yup.string(),
  clientEngagement2: yup.string(),
  clientEngagement3: yup.string(),
  clientEngagement4: yup.string(),
  keyInvestorsImg1: yup.string(),
  keyInvestorsImg2: yup.string(),
  keyInvestorsImg3: yup.string(),
  keyInvestorsImg4: yup.string(),
  keyInvestorsImg5: yup.string(),
  keyInvestorsName1: yup.string(),
  keyInvestorsName2: yup.string(),
  keyInvestorsName3: yup.string(),
  keyInvestorsName4: yup.string(),
  keyInvestorsName5: yup.string(),
  isHighCommitenceProject: yup.boolean(),
  isHideCompanyDetails: yup.boolean(),
  isFeatured: yup.boolean(),
  isPublished: yup.boolean(),
  irr: yup.number().positive().max(100).min(0),
  ticketSize: yup
    .number()
    .min(0)
    .lessThan(yup.ref('raiseGoal'), 'Ticket Size Amount should be less than Raise Goal'),
  assetClass: yup.string(),
  location: yup.string(),
  raiseGoal: yup
    .number()
    .min(0)
    .lessThan(yup.ref('overallRaiseGoal'), 'Raise Goal should be less than Overall Raise Goal'),
  overallRaiseGoal: yup.number().min(0),
  legalDocuments: yup.string().url(),
  projectSummary: yup.string().max(500),
  closingDate: yup.date(),
  companyName: yup.string(),
  companyDetails: yup.string().max(700),
  companyWebsite: yup.string().url(),
  companyLogo: yup.string().min(30),
  projectCover: yup.string().min(30),
  subscriptionAgreement: yup.string().min(30),
  investmentTimelineStartDate: yup.date().optional(),
  investmentTimelineEndDate: yup.date().optional(),
  shipmentStatus: yup.string(),
  expectedShipmentCompletionDate: yup.date().optional(),
  plannedShipmentCompletionDate: yup.date().optional(),
  productVideoURL: yup.string().optional(),
  productImages: yup.array().of(yup.string()).optional(),
})

const homeSchema = yup.object({
  keyInvestorsImg1: yup.string().optional().nullable(),
  keyInvestorsImg2: yup.string().optional().nullable(),
  keyInvestorsImg3: yup.string().optional().nullable(),
  keyInvestorsImg4: yup.string().optional().nullable(),
  keyInvestorsImg5: yup.string().optional().nullable(),
  keyInvestorsName1: yup.string().optional().nullable(),
  keyInvestorsName2: yup.string().optional().nullable(),
  keyInvestorsName3: yup.string().optional().nullable(),
  keyInvestorsName4: yup.string().optional().nullable(),
  keyInvestorsName5: yup.string().optional().nullable(),
})

const opportunityDocumentSchema = yup.object({
  title: yup.string().optional(),
  url: yup.string().when('title', {
    is: (title: string) => title && title.trim() !== '',
    then: yup.string().required('URL is required if document title is filled'),
    otherwise: yup.string().optional(),
  }),
})

const termObjectSchema = yup.object({
  title: yup.string().optional(),
  content: yup.string().optional(),
})

const opportunityTermSchema = yup.object({
  level1: yup
    .object({
      header: yup.string().optional(),
      termObjects: yup
        .array()
        .of(termObjectSchema)
        .test(
          'termObjects-empty-validation',
          'Term objects must have non-empty title and content when header is provided.',
          function (value) {
            const { header } = this.parent
            if (header && header.trim() !== '') {
              const invalidItems = Array.isArray(value)
                ? value?.filter(
                    (item) =>
                      !item.title ||
                      !item.content ||
                      item.title.trim() === '' ||
                      item.content.trim() === '',
                  )
                : []

              return invalidItems.length === 0
            }
            return true
          },
        )
        .test(
          'header-empty-validation',
          'Header must not be empty when title and content are provided',
          function (value) {
            const { header } = this.parent
            if (!header || (header && header.trim() === '')) {
              const invalidItems = Array.isArray(value)
                ? value?.filter(
                    (item) =>
                      (item.title && item.title.trim() !== '') ||
                      (item.content && item.content.trim() !== ''),
                  )
                : []

              return invalidItems.length === 0
            }
            return true
          },
        ),
    })
    .required(),
  level2: yup
    .object({
      header: yup.string().optional(),
      termObjects: yup
        .array()
        .of(termObjectSchema)
        .test(
          'termObjects-empty-validation',
          'Term objects must have non-empty title and content when header is provided.',
          function (value) {
            const { header } = this.parent
            if (header && header.trim() !== '') {
              const invalidItems = Array.isArray(value)
                ? value?.filter(
                    (item) =>
                      !item.title ||
                      !item.content ||
                      item.title.trim() === '' ||
                      item.content.trim() === '',
                  )
                : []

              return invalidItems.length === 0
            }
            return true
          },
        )
        .test(
          'header-empty-validation',
          'Header must not be empty when title and content are provided',
          function (value) {
            const { header } = this.parent
            if (!header || (header && header.trim() === '')) {
              const invalidItems = Array.isArray(value)
                ? value?.filter(
                    (item) =>
                      (item.title && item.title.trim() !== '') ||
                      (item.content && item.content.trim() !== ''),
                  )
                : []

              return invalidItems.length === 0
            }
            return true
          },
        ),
    })
    .required(),
})

const opportunityMetadataSchema = yup.object({
  company: yup.object().shape({
    about: yup.string().min(0).optional(),
  }),
})

const opportunitySchema = yup.object({
  opportunity_title: yup.string().required(_.isRequiredMessage('Opportunity Name')),
  opportunity_slug: yup.string(),
  jurisdiction: yup.string().required(_.isRequiredMessage('Jurisdiction')),
  currency_code: yup.string().required(_.isRequiredMessage('Currency Code')),
  is_featured: yup.boolean().optional().default(false),
  asset_class: yup.string().required(_.isRequiredMessage('Asset Class')).oneOf(assetsClass),
  status: yup.string().required(_.isRequiredMessage('Status')).oneOf(publishedStatus),
  video: yup.string().optional(),
  images: yup.array().of(yup.string()).required(_.isRequiredMessage('Images')),
  opportunity_summary: yup.string().required(_.isRequiredMessage('Opportunity Summary')),
  short_opportunity_summary: yup
    .string()
    .max(255, 'Short summary cannot exceed 255 characters')
    .required(_.isRequiredMessage('Short Opportunity Summary')),
  raise_goal: yup.number().required(_.isRequiredMessage('Raise Goal')),
  closing_date: yup
    .date()
    .required(_.isRequiredMessage('Closing Date'))
    .test(
      'greaterThanNow',
      'Closing date must be greater than now',
      (value) => !!value && value > new Date(),
    ),
  ticket_size: yup.number().required(_.isRequiredMessage('Ticket Size')),
  key_factors: yup.array().of(yup.string()).required(_.isRequiredMessage('Key Factors')),
  documents: yup.array().of(opportunityDocumentSchema).required(_.isRequiredMessage('Documents')),
  terms: opportunityTermSchema.required(_.isRequiredMessage('Terms')),
  highlights: yup.array().of(yup.string()).required(_.isRequiredMessage('Highlights')),
  cover_image: yup.string().optional(),
  metadata: opportunityMetadataSchema.optional(),
  investment_return: yup.number().optional(),
})

export {
  signInSchema,
  forgotPasswordSchema,
  emailInputSchema,
  projectSchema,
  homeSchema,
  opportunitySchema,
}
