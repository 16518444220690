/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import {
  Button,
  Flex,
  H2,
  InputModal,
  Span,
  SelectDropdown,
  Line,
  EyeIcon,
  Card,
  P,
  Close,
  H4,
  Input,
} from '../../lib/components'
import './investment-detail.scss'
import { useBreakpoint } from '../../lib/utils/hooks'
import { XL } from '../../lib/utils/constants'
import {
  displayCurrency,
  displayDollar,
  formatPercentage,
  formatStatus,
} from '../../lib/utils/helpers'
import { InvestmentStatus } from '../../lib/utils/message'

const InvestmentDetailPresenter = ({
  project,
  kyc,
  investorProfile,
  statuses,
  investmentDetail,
  handleFilePreview,
  handleStatus,
}: any) => {
  const { breakpoint } = useBreakpoint()
  const [displayInvestmentStatusModal, setDisplayInvestmentStatusModal] = useState(false)
  const [investmentStatusValue, setInvestmentStatusValue] = useState(
    investmentDetail.investmentStatus,
  )
  const [reason, setReason] = useState<string>('')

  return (
    <Flex variant='column' classes={['investment-detail-wrapper']}>
      <H2
        text='Investment Detail'
        classes={['align-center', 'investment-detail-wrapper-heading']}
      />

      <Flex variant={breakpoint && breakpoint < XL ? 'column' : 'row'}>
        {project && project.opportunity_title && (
          <Card classes={['p-40', 'mb-48', 'mr-16']}>
            <P text='Opportunity Details' />
            <Flex variant='space-between'>
              <Span variant='bold' text='Opportunity Name' />
              <Span variant='thin' text={project.opportunity_title} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Asset class' />
              <Span variant='thin' text={project.asset_class} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Ticket Size' />
              <Span variant='thin' text={displayCurrency(project.ticket_size)} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='IRR' />
              <Span variant='thin' text={formatPercentage(project.investment_return)} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Raise Goal' />
              <Span variant='thin' text={displayCurrency(project.raise_goal)} />
            </Flex>
          </Card>
        )}

        {investorProfile && investorProfile.email && (
          <Card classes={['p-40', 'mb-48', 'mr-16']}>
            <P text='Investor Details' />
            <Flex variant='space-between'>
              <Span variant='bold' text='Name' />
              <Span variant='thin' text={investorProfile.name} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Email' />
              <Span variant='thin' text={investorProfile.email} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Phone Number' />
              <Span variant='thin' text={investorProfile.phoneNumber} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Country of Residence' />
              <Span variant='thin' text={investorProfile.countryOfResidence} />
            </Flex>
          </Card>
        )}
      </Flex>
      <Flex variant={breakpoint && breakpoint < XL ? 'column' : 'row'}>
        {kyc && kyc.kycStatus && (
          <Card classes={['p-40', 'mb-48', 'mr-16']}>
            <P text='KYC Details' />
            <Flex variant='space-between'>
              <Span variant='bold' text='Status' />
              <Span variant='thin' text={kyc.kycStatus} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Investor Type' />
              <Span variant='thin' text={kyc.investorType} />
            </Flex>
          </Card>
        )}
        {investmentDetail && (
          <Card classes={['p-40', 'mb-48', 'mr-16']}>
            <P text='Investment Details' />
            <Flex variant='space-between'>
              <Span variant='bold' text='Investment Amount' />
              <Span
                variant='thin'
                text={displayDollar(investmentDetail.amount, investmentDetail.currency_code)}
              />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Investment Status' />
              <Span variant='thin' text={formatStatus(investmentDetail.status)} />
            </Flex>
            {investmentDetail?.metadata?.agreement && (
              <Flex variant='space-between'>
                <Span variant='bold' text='Agreement Copy' />
                <EyeIcon
                  classes={['align-center', 'pointer', 'mt-4']}
                  height='24px'
                  width='24px'
                  onClick={async () => {
                    if (investmentDetail.metadata.agreement) {
                      const url = await handleFilePreview(investmentDetail.metadata.agreement)
                      url && window.open(url, '_blank')
                    }
                  }}
                />
              </Flex>
            )}
            {investmentDetail?.metadata?.paymentCopy && (
              <Flex variant='space-between'>
                <Span variant='bold' text='Payment Copy' />
                <EyeIcon
                  classes={['align-center', 'pointer', 'mt-4']}
                  height='24px'
                  width='24px'
                  onClick={async () => {
                    if (investmentDetail.metadata.paymentCopy) {
                      const url = await handleFilePreview(investmentDetail.metadata.paymentCopy)
                      url && window.open(url, '_blank')
                    }
                  }}
                />
              </Flex>
            )}
            <Flex variant='space-between' classes={['mt-16']}>
              {displayInvestmentStatusModal ? (
                <InputModal>
                  <Flex variant='column' classes={['input-modal']}>
                    <Flex variant='space-between'>
                      <H4 text={'Update Investment Status'} />
                      <Close
                        height='24px'
                        onClick={() => {
                          setDisplayInvestmentStatusModal(false)
                          setInvestmentStatusValue(investmentDetail.investmentStatus)
                        }}
                      />
                    </Flex>
                    <Line />
                    <Flex variant='column' classes={['reply-section']}>
                      <Span variant='bold' text='Are you sure? You want to update.' />
                      <SelectDropdown
                        name='investmentStatus'
                        id='investmentStatusId'
                        formId=''
                        list={InvestmentStatus}
                        value={investmentStatusValue || investmentDetail.investmentStatus}
                        handleChange={(val) => {
                          setInvestmentStatusValue(val)
                        }}
                      />
                      {investmentStatusValue &&
                        (investmentStatusValue === 'CLOSED' ||
                          investmentStatusValue === 'REJECTED') && (
                          <Input
                            labelText='Reason'
                            type='text'
                            name='reason'
                            placeholder='Enter reason for rejection'
                            value={reason}
                            handleChange={(e) => setReason(e.target.value)}
                          />
                        )}
                      <Button
                        classes={['mt-24']}
                        variant='primary-btn'
                        text='Update'
                        handleClick={() => {
                          handleStatus(investmentStatusValue, 'investmentStatus', reason.trim())
                          setDisplayInvestmentStatusModal(false)
                        }}
                      />
                    </Flex>
                  </Flex>
                </InputModal>
              ) : null}
              <Button
                variant='primary-btn'
                text='Update Investment Status'
                isDisabled={
                  investmentDetail?.status === 'APPROVED' ||
                  investmentDetail?.status === 'REJECTED' ||
                  investmentDetail?.status === 'CLOSED'
                }
                handleClick={() => {
                  setDisplayInvestmentStatusModal(true)
                }}
              />
            </Flex>
          </Card>
        )}
      </Flex>

      <Flex variant='column' classes={['table_scroll_horizontal']}>
        {statuses && statuses.length > 0 && (
          <>
            <H4 text='Status logs' />
          </>
        )}
        {statuses && statuses.length > 0 && (
          <table className='styled-table'>
            <thead>
              <tr>
                <th>Updated By</th>
                <th>Updated At</th>
                <th>Investment Status</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody>
              {statuses
                .sort((a: any, b: any) => (a.updatedAt < b.updatedAt ? 1 : -1))
                .map((doc: any) => {
                  return (
                    !!doc.id && (
                      <tr key={doc.updatedAt}>
                        <td>{doc.email}</td>
                        <td>{`${new Date(doc.updatedAt).toLocaleString()}`}</td>
                        <td>{formatStatus(doc.investmentStatus)}</td>
                        <td>{formatStatus(doc.paymentStatus)}</td>
                      </tr>
                    )
                  )
                })}
            </tbody>
          </table>
        )}
      </Flex>
    </Flex>
  )
}

export { InvestmentDetailPresenter }
