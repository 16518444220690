/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import './project-detail-container.scss'
import {
  H3,
  Flex,
  Card,
  Image,
  P,
  ProgressBar,
  H6,
  Button,
  CheckboxInput,
  Span,
} from '../../lib/components'
import { INVEST, VIEW_DOCUMENTS } from '../../lib/utils/message'
import { CompanyDetailProps } from '../../lib/types'
import {
  calculateCommittedPercentage,
  calculateRemainingDays,
  displayPercentage,
  displayDollar,
} from '../../lib/utils/helpers'

const CompanyCard = ({
  project,
  viewDocumentHandleClick,
  investHandleClick,
}: CompanyDetailProps) => {
  return (
    <Card classes={['company-card']}>
      <Flex classes={['company-card-top-section']}>
        <Image variant='round' source={project?.companyLogo} />
        <H3 classes={['ml-16']} text={project?.companyName || ''} />
      </Flex>
      {Number(project?.noOfInvestors) > 0 && (
        <Flex variant='row-center'>
          <P variant='default-red' text={`Backed by ${project?.noOfInvestors} investors`} />
        </Flex>
      )}
      <Flex variant='column' classes={['m-32', 'company-card-checkbox-section']}>
        {project?.companyMilestones?.map((data) => {
          return (
            data && (
              <CheckboxInput labelText={data} name='' value={'true'} handleChange={() => {}} />
            )
          )
        })}
      </Flex>
      <Flex variant='row-center' classes={['company-card-container']}>
        <Card classes={['company-property-card', 'mb-16']}>
          <H3 text={displayPercentage(project?.irr) || ''} />
          <P classes={['mt-8']} textSize='small' variant='default-gray' text={'IRR'} />
        </Card>
        <Card classes={['company-property-card', 'mb-16']}>
          <H3 text={displayDollar(project?.ticketSize, project?.currencyCode) || ''} />
          <P classes={['mt-8']} textSize='small' variant='default-gray' text={'Ticket size'} />
        </Card>
        <Card classes={['company-property-card', 'mb-16']}>
          <H3 text={displayDollar(project?.valuation, project?.currencyCode) || ''} />
          <P classes={['mt-8']} textSize='small' variant='default-gray' text={'Valuation'} />
        </Card>
        <Card classes={['company-property-card', 'mb-16']}>
          <H3 text={displayDollar(project?.raiseGoal, project?.currencyCode) || ''} />
          <P classes={['mt-8']} textSize='small' variant='default-gray' text={'Raise goal'} />
        </Card>
      </Flex>
      <Flex classes={['default-progress-card', 'mr-32', 'mb-40', 'width-auto-progress-card']}>
        <H6
          text={`${calculateCommittedPercentage(
            project?.committedAmount || '',
            displayDollar(project?.raiseGoal, project?.currencyCode) || '',
          )}% committed`}
        />
        <ProgressBar
          variant='red-bar'
          value={calculateCommittedPercentage(
            project?.committedAmount || '',
            displayDollar(project?.raiseGoal, project?.currencyCode) || '',
          )}
          maxValue='100'
        />
        <Flex variant='space-between'>
          <Flex variant='row-center'>
            <P textSize='small' variant='default-gray' text={`${project?.committedAmount} of`} />
            <H6
              classes={['ml-8']}
              text={displayDollar(project?.raiseGoal, project?.currencyCode) || ''}
            />
          </Flex>
          <H6
            text={`${
              project?.closingDate &&
              calculateRemainingDays(new Date(), new Date(project?.closingDate))
            } days left`}
          />
        </Flex>
      </Flex>
      <Button
        classes={['company-card-btn', 'ml-32', 'mr-32', 'mb-12']}
        variant='primary-btn'
        text={INVEST}
        handleClick={investHandleClick}
      />
      <Button
        classes={['company-card-btn', 'ml-32', 'mr-32', 'mb-32']}
        text={VIEW_DOCUMENTS}
        variant='basic-btn'
        handleClick={() => viewDocumentHandleClick(project?.legalDocuments || '')}
      />
    </Card>
  )
}

export { CompanyCard }
