import React from 'react'
import 'dayjs/locale/en-gb'
import './datepicker-component.scss'
import { DemoItem as Container } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker'
import { convertDateToDayjs, concatClassName } from '../../utils/helpers'

interface DatePickerProps {
  label?: string
  value: Date | null
  minDate?: Date
  format?: string
  classes?: string[]
  changeHandler: (e: any) => void
}

const DatePicker = ({
  label,
  value,
  minDate,
  changeHandler,
  classes,
  format = 'DD/MM/YY',
}: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
      <Container>
        <Picker
          label={label}
          value={convertDateToDayjs(value)}
          minDate={convertDateToDayjs(minDate)}
          format={format}
          onChange={(e) => changeHandler(e?.toDate())}
          className={concatClassName(classes)}
        />
      </Container>
    </LocalizationProvider>
  )
}
//
export { DatePicker }
