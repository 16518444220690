/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { H4 } from '../Heading'
import { Close } from '../Icon'
import { Line } from '../Line'
import { P } from '../P'
import './confirmation-modal-component.scss'
import { RootState, store } from '../../../store'
import { useSelector } from 'react-redux'
import {
  showModelTimeout,
  hideModel,
  confirmActionModel,
} from '../../../global-state/confirm-model-slice'
import { InputModal } from '../InputModal'

function ConfirmationModal() {
  const { isShow, message, isConfirm, name } = useSelector((state: RootState) => state.confirmModel)
  useEffect(() => {
    if (isConfirm) {
      store.dispatch(showModelTimeout())
    }
  }, [isConfirm])

  return (
    isShow && (
      <InputModal>
        <Flex variant='column' classes={['input-modal']}>
          <Flex variant='space-between'>
            <H4 text={name} />
            <Close
              height='24px'
              classes={['pointer']}
              onClick={() => {
                store.dispatch(hideModel())
              }}
            />
          </Flex>
          <Line />
          <Flex variant='column' classes={['reply-section']}>
            <P variant='default-gray' text={message} />
            <Line />
            <div className='confirmation-model-button-container'>
              <Button
                variant='secondary-btn'
                text='Cancel'
                handleClick={() => {
                  store.dispatch(hideModel())
                }}
              />
              <Button
                variant='primary-btn'
                text='Confirm'
                handleClick={() => {
                  store.dispatch(confirmActionModel())
                }}
              />
            </div>
          </Flex>
        </Flex>
      </InputModal>
    )
  )
}

export { ConfirmationModal }
