import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { IGoogleReCaptchaProps } from '../../types'

const SITE_KEY = process.env.REACT_APP_SITE_KEY || '6Ldkw-4gAAAAANqEzL1AglUQ8qzn1XEBZtAefnhb'

const GoogleReCaptcha = ({
  handleChange,
  handleExpired,
  reCaptchaRef,
  size = 'normal',
}: IGoogleReCaptchaProps) => {
  return (
    //I used my personal credentials like sitekey 6LcREdQgAAAAAL6e-UR51EyvBYoQFBN5MoQtQ0hZ for testing ReCaptcha
    // set this sitekey into .env file
    <ReCAPTCHA
      sitekey={SITE_KEY}
      onChange={handleChange}
      size={size}
      onExpired={handleExpired}
      ref={reCaptchaRef}
    />
  )
}

export { GoogleReCaptcha }
