import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch, modelState } from '../lib/types'

const confirmModelSlice = createSlice({
  name: 'confirmModel',
  initialState: {
    name: '',
    isShow: false,
    isConfirm: false,
    message: '',
  },
  reducers: {
    showModel: (state: modelState, action: any) => {
      state.isShow = true
      state.isConfirm = false
      state.message = action.payload.message
      state.name = action.payload.name
    },
    hideModel: (state: modelState) => {
      state.isShow = false
      state.isConfirm = false
      state.message = ''
      state.name = ''
    },
    confirmActionModel: (state: modelState) => {
      state.isConfirm = true
      state.isShow = false
    },
  },
})
export const showModelTimeout = () => (dispatch: AppDispatch) => {
  setTimeout(() => {
    dispatch(hideModel())
  }, 1000)
}

export { confirmModelSlice }
export const { showModel, hideModel, confirmActionModel } = confirmModelSlice.actions
