import React, { useEffect, useState, useCallback } from 'react'
import './input-file-component.scss'
import { InputFileProps, FileDataProps, Event, MultiInputFileProps } from '../../types'
import { FileUploadIcon, FileUploadedIcon, DeleteIcon } from '../Icon'
import { ERROR, SUCCESS } from '../../utils/message'
import { StatusLabel } from '../StatusLabel'
import { CheckCircleIcon, ReportIcon } from '../Icon/icon-component'

const InputFile = ({
  label,
  name,
  handleFiles,
  infoText,
  file,
  type = 'image',
  errors,
  touched,
  dirty,
  handleDeleteFile,
}: InputFileProps) => {
  const [fileData, setFileData] = useState<FileDataProps | undefined>(file)
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const [date, setDate] = useState(new Date())
  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])

  const getAcceptType = () => {
    switch (type) {
      case 'doc':
        return 'application/pdf'
      case 'video':
        return 'video/mp4'
      case 'image':
      default:
        return 'image/jpg, image/jpeg'
    }
  }

  const manageFiles = (e: Event) => {
    const files = e.target.files
    if (files) setFileData(files[0])
    if (files) handleFiles(files[0])
  }
  const handleDeleteClick = () => {
    setFileData(undefined)
    handleDeleteFile && handleDeleteFile()
  }

  useEffect(() => {
    setFileData(file)
    if (file?.date) {
      setDate(new Date(file.date))
    }
  }, [file])

  return (
    <div className='input-file-main-container'>
      <span> {label} </span>
      <label
        className={`${fileData ? 'input-file-container selected' : 'input-file-container'}`}
        htmlFor={name}
      >
        {!fileData ? (
          <>
            <FileUploadIcon />
            <div className='input-file-label-container'>
              <span className='input-file-label mb-8'>Browse to upload</span>
              <span className='input-file-sub-label'>
                {type === 'image' && 'Supported file types: jpeg, jpg upto 10MB'}
                {type === 'video' && 'Supported file types: mp4 upto 10MB'}
                {type === 'doc' && 'Supported file types: pdf upto 10MB'}
              </span>
            </div>{' '}
          </>
        ) : (
          <>
            <FileUploadedIcon />
            <div className='input-file-label-container'>
              <span className='input-file-label mb-8'> {fileData.name} </span>
              <span className='input-file-sub-label'>
                {fileData &&
                  `Uploaded  ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}{' '}
              </span>
            </div>
            <span className='input-file-delete-icon'>
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (e.isDefaultPrevented() && e.isPropagationStopped()) handleDeleteClick()
                }}
              />
            </span>
          </>
        )}
        <input
          id={name}
          className='input-file'
          name={name}
          type='file'
          onChange={(e: Event) => manageFiles(e)}
          onClick={(e: any) => {
            e.target.value = null
          }}
          accept={getAcceptType()}
        ></input>
      </label>
      {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
      {!isError && infoText && <StatusLabel type='info' variant='basic' text={infoText} />}
    </div>
  )
}

const MultiInputFile = ({
  label,
  name,
  handleFiles,
  file,
  type = 'image',
  errors,
  touched,
  dirty,
  handleDeleteFile,
}: MultiInputFileProps) => {
  const [fileData, setFileData] = useState<FileDataProps[] | undefined>(file)
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const date = new Date()
  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])
  const manageFiles = (e: Event) => {
    const files = e.target.files
    if (files) setFileData([...files])
    if (files) handleFiles([...files])
  }
  const handleDeleteClick = () => {
    setFileData(undefined)
    handleDeleteFile && handleDeleteFile()
  }

  const handleFileDelete = (removeIndex: number) => {
    const filtered = fileData?.filter((data, index) => index !== removeIndex)
    setFileData(filtered)
    handleFiles(filtered)
    if (filtered?.length === 0) setFileData(undefined)
  }

  return (
    <div className='input-file-main-container'>
      <span> {label} </span>
      <label
        className={`${fileData ? 'input-file-container selected' : 'input-file-container'}`}
        htmlFor={name}
      >
        {!fileData ? (
          <>
            <FileUploadIcon />
            <div className='input-file-label-container'>
              <span className='input-file-label mb-8'>Browse to upload</span>
              <span className='input-file-sub-label'>
                {type === 'image'
                  ? 'Supported file types: jpeg, jpg upto 10MB'
                  : 'Supported file types: pdf upto 10MB each'}
              </span>
            </div>{' '}
          </>
        ) : (
          <>
            {fileData && fileData.length > 0 ? '' : <FileUploadedIcon />}
            <div className='input-file-label-container'>
              <ol>
                {fileData &&
                  fileData.map((obj, index): any => (
                    <li key={index}>
                      <div className='file-list'>
                        <span key={index} className='input-file-label mb-8 mr-4'>
                          {' '}
                          {obj.name}{' '}
                        </span>
                        <DeleteIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            if (e.isDefaultPrevented() && e.isPropagationStopped())
                              handleFileDelete(index)
                          }}
                        />
                      </div>
                    </li>
                  ))}
              </ol>
              <span className='input-file-sub-label'>
                {fileData &&
                  `Uploaded  ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}{' '}
              </span>
            </div>
            <span className='input-file-delete-icon'></span>
          </>
        )}
        <input
          id={name}
          className='input-file'
          name={name}
          type='file'
          onChange={(e: Event) => manageFiles(e)}
          multiple
          accept={type === 'doc' ? 'application/pdf' : 'image/jpg, image/jpeg'}
        ></input>
      </label>
      {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
    </div>
  )
}

export { InputFile, MultiInputFile }
