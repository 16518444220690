import React from 'react'
import { FlexCenter } from '../../lib/components'
import { ResetPasswordInstructions } from './reset-instructions-presenter'
import { useNavigate } from 'react-router-dom'

const ResetInstructionsContainer = () => {
  const navigator = useNavigate()

  const handleClick = () => navigator('/')
  return (
    <FlexCenter variant={'column-center'} classes={['mt-0']}>
      <ResetPasswordInstructions handleClick={handleClick} />
    </FlexCenter>
  )
}

export { ResetInstructionsContainer }
