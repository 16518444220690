/* eslint-disable @typescript-eslint/no-empty-function */

import React, { useEffect, useState } from 'react'
import './project-detail-container.scss'
import {
  Button,
  LeftArrowIcon,
  Span,
  H1,
  H3,
  H6,
  Flex,
  P,
  Card,
  BookmarkIcon,
  ProgressBar,
  Image,
  Tag,
  AnchorLabel,
  LinkIcon,
  LocationIcon,
} from '../../lib/components'
import { BACK_TO_OPPORTUNITY, INVEST } from '../../lib/utils/message'
import {
  calculateCommittedPercentage,
  displayDollar,
  displayPercentage,
  calculateRemainingDays,
} from '../../lib/utils/helpers'
import { ProjectDetailProps } from '../../lib/types'

const ProjectDetailTop = ({ project, investHandleClick }: ProjectDetailProps) => {
  const BusinessLogo = `${process.env.PUBLIC_URL}/assets/businessLogo.jpg`
  const backToProjectHandler = () => {}

  return (
    <>
      <div>
        <Flex>
          <Button
            classes={['auto-small-btn-width']}
            variant='secondary-btn'
            handleClick={backToProjectHandler}
            IconComponent={<LeftArrowIcon />}
          />
          <Span classes={['ml-16', 'font-16']} variant='bold' text={BACK_TO_OPPORTUNITY} />
        </Flex>
        <Flex classes={['mt-24', 'flex-align-center']}>
          <H1 classes={['mr-16']} text={project?.projectName || ''} />
          {project?.assetClass && <Tag classes={['margin-4']} text={project?.assetClass} />}
          {project?.isHighCommitenceProject && (
            <Tag
              classes={['margin-4']}
              text={project?.isHighCommitenceProject && 'High Committance Rate'}
            />
          )}
        </Flex>
        {Number(project?.noOfInvestors) > 0 && (
          <Flex classes={['mt-24', 'project-sub-heading']}>
            <P variant='default-blue' text={`Backed by ${project?.noOfInvestors} investors`} />
          </Flex>
        )}
        <Flex classes={['mt-16', 'project-sub-info']}>
          <AnchorLabel
            href={project?.companyWebsite || ''}
            text={project?.companyWebsite || ''}
            isDisabled={false}
            variant={'label-anchor'}
            IconComponent={<LinkIcon />}
          />
          <AnchorLabel
            classes={['ml-8']}
            href={project?.location || ''}
            text={project?.location || ''}
            isDisabled={true}
            variant={'label-anchor'}
            IconComponent={<LocationIcon />}
          />
        </Flex>
        <Flex variant='row-center'>
          <Card classes={['default-property-card', 'mt-32', 'mr-32']}>
            <H3 text={displayPercentage(project?.irr) || ''} />
            <P classes={['mt-8']} textSize='small' variant='default-gray' text={'IRR'} />
          </Card>
          <Card classes={['default-property-card', 'mt-32', 'mr-32']}>
            <H3 text={displayDollar(project?.ticketSize, project?.currencyCode) || ''} />
            <P classes={['mt-8']} textSize='small' variant='default-gray' text={'Ticket size'} />
          </Card>
          <Card classes={['default-property-card', 'mt-32', 'mr-32']}>
            <H3 text={displayDollar(project?.valuation, project?.currencyCode) || ''} />
            <P classes={['mt-8']} textSize='small' variant='default-gray' text={'Valuation'} />
          </Card>
          <Card classes={['default-property-card', 'mt-32', 'mr-32']}>
            <H3 text={displayDollar(project?.raiseGoal, project?.currencyCode) || ''} />
            <P classes={['mt-8']} textSize='small' variant='default-gray' text={'Raise goal'} />
          </Card>
          <Card classes={['default-progress-card', 'mt-32', 'mr-32']}>
            <H6
              text={`${calculateCommittedPercentage(
                project?.committedAmount || '',
                project?.raiseGoal || '',
              )}% commited`}
            />
            <ProgressBar
              variant='green-bar'
              value={calculateCommittedPercentage(
                project?.committedAmount || '',
                displayDollar(project?.raiseGoal, project?.currencyCode) || '',
              )}
              maxValue='100'
            />
            <Flex variant='space-between'>
              <Flex variant='row-center'>
                <P
                  textSize='small'
                  variant='default-gray'
                  text={`${project?.committedAmount} of`}
                />
                <H6
                  classes={['ml-8']}
                  text={displayDollar(project?.raiseGoal, project?.currencyCode) || ''}
                />
              </Flex>
              <H6
                text={`${
                  project?.closingDate &&
                  calculateRemainingDays(new Date(), new Date(project?.closingDate))
                } days left`}
              />
            </Flex>
          </Card>
        </Flex>
        <Flex classes={['btn-div', 'mt-40']}>
          <Button
            classes={['auto-btn-width']}
            text={INVEST}
            variant='primary-btn'
            handleClick={investHandleClick}
          />
        </Flex>
      </div>
      <Flex classes={['mr-68', 'img-height']}>
        <Image
          classes={['project-cover-image']}
          source={project?.projectCover || BusinessLogo}
          variant='all'
          radius='r16'
        />
      </Flex>
    </>
  )
}

export { ProjectDetailTop }
