import React from 'react'
import './opportunity-card-component.scss'
import { LastMinuteListPropsV1, OpportunityCardProps } from '../../types'
import { calculateRemainingDays } from '../../utils/helpers'
import { H4 } from '../Heading'
import { EyeIcon, EditIcon } from '../Icon'

function OpportunityCard({
  id,
  opportunitySlug,
  opportunityTitle,
  assetClass,
  expectedReturn,
  ticketSize,
  handleClick,
  isPublished,
  committedPercentage,
  handleProjectInvestmentClick,
  closingDate,
}: OpportunityCardProps) {
  const closeDays = Number(closingDate && calculateRemainingDays(new Date(), new Date(closingDate)))
  return (
    <tr key={id}>
      <td>{opportunityTitle}</td>
      <td>{assetClass}</td>
      <td> {expectedReturn} </td>
      <td> {ticketSize} </td>
      <td> {committedPercentage} </td>
      <td>{closeDays < 2 ? `${closeDays} day left` : `${closeDays} days left`}</td>
      <td> {isPublished ? 'Published' : 'Unpublished'}</td>
      <td>
        <EyeIcon
          classes={['align-center', 'pointer']}
          height='24px'
          width='24px'
          onClick={() => handleProjectInvestmentClick(opportunitySlug, id)}
        />
      </td>
      <td>
        <EditIcon
          classes={['align-center', 'pointer']}
          height='24'
          width='24'
          onClick={() => handleClick(opportunitySlug, id)}
        />
      </td>
      {/* <td>
    <DeleteIcon
      classes={['align-center', 'pointer']}
      onClick={() => handleProjectDelete(id)}
    />
  </td> */}
    </tr>
  )
}
export { OpportunityCard }

function OpportunityCardHorizontal({
  opportunitySlug: opportunity_slug,
  opportunityTitle: opportunity_title,
  committedPercentage,
  opportunitySummary: opportunity_summary,
  opportunityCover: opportunity_cover,
  handleClick,
  closingDate: closing_date,
}: LastMinuteListPropsV1) {
  return (
    <div
      className='project-h-container'
      onClick={() => handleClick && handleClick(opportunity_slug)}
    >
      <div className='project-h-image'>
        <img src={opportunity_cover} alt='' />
      </div>
      <div className='project-h-content'>
        <div className='project-h-text'>
          <H4 text={opportunity_title} />
          <p>{opportunity_summary}</p>
        </div>
        <div className='project-h-stats'>
          <p>
            <strong>{committedPercentage}</strong> committed
          </p>
          <p>
            {closing_date && calculateRemainingDays(new Date(), new Date(closing_date))}{' '}
            <strong>days</strong>
          </p>
        </div>
      </div>
    </div>
  )
}

export { OpportunityCardHorizontal }
