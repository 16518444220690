import { AxiosError } from 'axios'
import { axiosInstance } from '.'
import { InvestorProps, Role, AxiosResponse } from '../lib/types'
import { INVESTORS_LIST_V1, INVESTOR_PROFILE_v1, LOCK_UNLOCK_ACCOUNT } from './endpoints'
import { removeEmptyValueFromObject } from '../lib/utils/helpers'

export const investorServices = {
  getInvestorProfile: async (investorId: number) => {
    try {
      // const data = await axiosInstance.get(INVESTOR_PROFILE(investorId))
      const data = await axiosInstance.get(INVESTOR_PROFILE_v1(investorId))
      const profileData: InvestorProps = {
        userId: data.data.id,
        accountStatus: data.data.status,
        investmentLimit: data.data.investment_limit,
        citizenship: data.data.country ?? '',
        countryCode: data.data.countryCode ?? '',
        countryOfResidence: data.data.country ?? '',
        email: data.data.email ?? '',
        name: data.data.name ?? '',
        passportNumber: data.data.passportNumber ?? '',
        phoneNumber: data.data.phone ?? '',
        kycStatus: data.data.status,
        isEmailVerified: data.data.is_verified,
        role: data.data.role,
      }
      return profileData
    } catch (error: any) {
      return error.response
    }
  },

  lockUnlockAccount: async (investorId: number, details: { [key: string]: string | number }) => {
    try {
      const data: any = await axiosInstance.post(LOCK_UNLOCK_ACCOUNT(investorId), details)
      const profileData: InvestorProps = {
        userId: data.data.userId,
        accountStatus: data.data.accountStatus,
        investmentLimit: data.data.investmentLimit,
        citizenship: data.data.citizenship || '',
        countryCode: data.data.countryCode || '',
        countryOfResidence: data.data.countryOfResidence || '',
        email: data.data.email || '',
        name: data.data.name || '',
        passportNumber: data.data.passportNumber || '',
        phoneNumber: data.data.phoneNumber || '',
        kycStatus: data.data.kycStatus,
        isEmailVerified: data.data.isEmailVerified || false,
      }
      return profileData
    } catch (error: any) {
      return error.response
    }
  },

  // v1
  getInvestorsList: async (roles: Role, params: object) => {
    try {
      const data: any = await axiosInstance.get(INVESTORS_LIST_V1, {
        params: removeEmptyValueFromObject(params),
      })
      return {
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        data: data.data.investors,
        status: 200,
      }
    } catch (error: any) {
      return error.response
    }
  },
}
