/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable arrow-spacing */
import React from 'react'
import { CBLogoV2 } from '../Icon'
import { P, Span, FlexCenter, Flex } from '..'
import './footer.scss'

export const Footer = () => {
  return (
    <div className='footer'>
      <FlexCenter>
        <br />
        <CBLogoV2 width='35%' />
        <br />
      </FlexCenter>
      <FlexCenter>
        <P text={'Credibila Markets © 2024 All Rights Reserved.'} />
      </FlexCenter>
    </div>
  )
}
