/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  H2,
  ReportIcon,
  P,
  Button,
  H4,
  Input,
  Close,
  Line,
  Flex,
  SelectDropdown,
  InputModal,
  Span,
  MultiInputFile,
  CheckCircleIcon,
  ToolTip,
} from '../../lib/components'
import { investorServices, jobServices, projectServices, investmentServices } from '../../services'
import { Jobs, Role, SearchParams, InvestorPropsV1 } from '../../lib/types'
import { RootState } from '../../store'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { EyeIcon, EditIcon } from '../../lib/components/Icon'
import { FormikProps, Formik } from 'formik'
import { showBanner } from '../../global-state/banner-slice'
import { investorFilter, kycStatusMap } from '../../lib/utils/message'
import { useBreakpoint } from '../../lib/utils/hooks'
import { SM } from '../../lib/utils/constants'
import { Pagination } from '../../lib/components/Pagination'
import {
  displayDollar,
  formatStatus,
  getFileKeyFromSignedUrl,
  isValidDocType,
  isValidFileSize,
  isValidImageType,
} from '../../lib/utils/helpers'
import './investor-list.scss'

const InvestorList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { breakpoint } = useBreakpoint()
  const { authToken } = useSelector((state: RootState) => state.gbcUser)
  const [investors, setInvestors] = useState<InvestorPropsV1[]>()
  const [searchParams, setSearchParams] = useState<SearchParams>({
    limit: '10',
    offset: '0',
  })
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    count: 5,
    offset: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [displayModal, setDisplayModal] = useState(false)
  const [displayQuarterlyModal, setDisplayQuarterlyModal] = useState(false)
  const [investorData, setInvestorData] = useState<InvestorPropsV1>()
  const [quarterlyReports, setQuarterlyReports] = useState([])
  const [jobs, setJobs] = useState<Jobs[]>([])

  useEffect(() => {
    fetchInvestorsList()
  }, [currentPage, searchParams])

  useEffect(() => {
    jobServices
      .getJobs(
        { type: 'quaterly_report' },
        {
          Authorization: authToken,
        },
      )
      .then((response) => {
        if (response.status === 200) {
          setJobs(response.jobs)
        }
      })
  }, [])

  const fetchInvestorsList = async () => {
    const data: any = await investorServices.getInvestorsList(Role.investor, searchParams)
    if (data && data.data && data.status === 200) {
      setInvestors(data.data)
      setPagination(data.pagination)
    }
  }

  const setInvestmentLimit = async (investorId: number, investmentLimit: number) => {
    // converting dollar value in cents
    const investmentLimitInCents = investmentLimit * 100
    const data: any = (await investmentServices.setInvestmentLimit(
      investorId,
      investmentLimitInCents,
    )) as InvestorPropsV1
    if (data && data.status === 200) {
      dispatch(
        showBanner({
          text: 'Investment limit updated',
          variant: 'success-banner',
        }),
      )
      fetchInvestorsList()
    } else {
      dispatch(
        showBanner({
          text:
            (data.data && data.data.message) ||
            'Something went wrong while updating investment limit',
          variant: 'error-banner',
        }),
      )
    }
  }
  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    const { limit, offset } = pagination
    setSearchParams({
      ...searchParams,
      offset: '0',
      limit: limit.toString(),
      [name]: value,
    })
    setCurrentPage(1)
    setPagination({ ...pagination, offset: 0 })
  }
  const resetSearchParams = () => {
    setSearchParams({ limit: '10', offset: '0', filterBy: '', keyword: '' })
  }
  const handleCurrentPage = (currentPage: number) => {
    const { limit } = pagination
    setCurrentPage(currentPage)
    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })
    setPagination({ ...pagination, offset: (currentPage - 1) * limit })
  }
  const handleCloseModal = () => {
    setDisplayModal((state) => !state)
  }

  const handleUserInvestmentsClick = (investorId: number) => {
    navigate(`/user/investments/${investorId}`)
  }

  const handleFileUpload = async (fileData: File, fileType: 'image' | 'doc') => {
    const isValidImage =
      fileType === 'image' ? isValidImageType(fileData) : isValidDocType(fileData)
    const isValidSize = isValidFileSize(fileData, fileType)
    if (isValidImage && isValidSize) {
      const data: any = await projectServices.postUploadFiles(fileData)
      if (data) return data
    } else {
      dispatch(
        showBanner({
          text: 'File size or type is incorrect for field',
          variant: 'error-banner',
        }),
      )
    }
  }

  const handleQuarterlyReportFiles = async (fileData: any) => {
    const promises = fileData.map(async (file: File) => {
      const data = await handleFileUpload(file, 'doc')
      if (data && data.data) {
        return data.data.Key
      }
      return undefined
    })
    const keys = await Promise.all(promises)
    const files = keys.filter((k) => k)
    if (files) {
      const payload = {
        type: 'quaterly_report',
        fileIds: files,
      }
      const data: any = await projectServices.postQuarterlyReportFiles(payload)
      if (data.status === 200)
        dispatch(
          showBanner({
            text: 'Quarterly report files are being processed by the system in the background. You will get a notification on the email once processing is completed',
            variant: 'success-banner',
          }),
        )
      else
        dispatch(
          showBanner({
            text: (data.data && data.data.message) || 'Something went wrong posting the job',
            variant: 'error-banner',
          }),
        )
    }
  }

  const handleFilePreview = async (fileKey: string) => {
    const signedUrl = getFileKeyFromSignedUrl(fileKey)
    const data = await projectServices.previewFile(signedUrl)
    if (data && data.data && data.status === 200) return data.data.url
    else {
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong preview document',
          variant: 'error-banner',
        }),
      )
      return ''
    }
  }

  return (
    <Flex variant='column-center'>
      <H2 classes={['mb-48']} text='Investors' />
      <Flex classes={['upload-quaterly-report-container']}>
        {displayQuarterlyModal ? (
          <InputModal>
            <Flex variant='column' classes={['input-modal']}>
              <Flex variant='space-between'>
                <H4 text={'Quaterly Report'} />
                <Close
                  height='24px'
                  onClick={() => {
                    setDisplayQuarterlyModal(false)
                    setQuarterlyReports([])
                  }}
                />
              </Flex>
              <Flex variant='column' classes={['reply-section']}>
                <Line />
                <MultiInputFile
                  name={'quaterlyReport'}
                  type='doc'
                  handleFiles={async (files: any) => {
                    if (files) {
                      setQuarterlyReports(files)
                    }
                  }}
                  label=''
                />
                <Line />
                <Button
                  classes={['mt-24']}
                  variant='primary-btn'
                  text='Upload'
                  isDisabled={quarterlyReports && quarterlyReports.length > 0 ? false : true}
                  handleClick={async () => {
                    await handleQuarterlyReportFiles(quarterlyReports)
                    setDisplayQuarterlyModal(false)
                    setQuarterlyReports([])
                  }}
                />
              </Flex>
            </Flex>
          </InputModal>
        ) : null}
        <Button
          classes={['mt-16', 'p-16']}
          variant='primary-btn'
          handleClick={() => {
            setDisplayQuarterlyModal(true)
          }}
          text={'Upload Quarterly Reports'}
        />
      </Flex>
      {displayModal ? (
        <InputModal>
          <Flex variant='column' classes={['input-modal']}>
            <Flex variant='space-between'>
              <H4 text={'Investment Limit'} />
              <Close height='24px' onClick={() => handleCloseModal()} />
            </Flex>
            <Line />
            <Flex variant='column' classes={['reply-section']}>
              <Input
                name='investmentLimit'
                placeholder='Investment Limit'
                type='number'
                required
                value={
                  (investorData &&
                    investorData.investment_limit &&
                    investorData.investment_limit) ||
                  ''
                }
                handleChange={(e) =>
                  investorData &&
                  setInvestorData({
                    ...investorData,
                    investment_limit: e.target.value,
                  })
                }
              />
              <Button
                variant='primary-btn'
                text='Save'
                handleClick={() => {
                  investorData &&
                    setInvestmentLimit(
                      investorData.investor_id,
                      Number(investorData.investment_limit),
                    )
                  handleCloseModal()
                }}
              />
            </Flex>
          </Flex>
        </InputModal>
      ) : null}

      <div className='investor-toolbar-layout gap-12 mb-16 mt-16'>
        <Input
          name='keyword'
          value={searchParams.keyword || ''}
          handleChange={(e) => handleSearchParams({ name: 'keyword', value: e.target.value })}
          placeholder='Search'
        />
        <SelectDropdown
          name='filterBy'
          id='filterId'
          formId=''
          list={investorFilter}
          value={searchParams && searchParams.filterBy}
          placeholder={'Filter'}
          handleChange={(val) => handleSearchParams({ name: 'filterBy', value: val })}
        />
        <div className='sort-reset'>
          <Button
            handleClick={resetSearchParams}
            classes={['reset-btn']}
            variant='secondary-btn'
            text='Reset'
          />
        </div>
      </div>
      {investors && investors.length ? (
        <div className='w-100'>
          <Flex variant='row' classes={['mb-24', 'table_scroll_horizontal']}>
            <table className='styled-table'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Account Status</th>
                  <th>NDA Status</th>
                  <th>KYC Status</th>
                  <th>Used Investment Limit</th>
                  <th>Investment Limit</th>
                  <th>Manage Profile / KYC</th>
                  <th>Investment Status</th>
                </tr>
              </thead>
              <tbody>
                {investors.map((investor) => {
                  return (
                    <tr key={uuidv4()}>
                      <td>{investor.name}</td>
                      <ToolTip
                        content={investor?.is_verified ? 'Email Verified' : 'Email Not Verified'}
                      >
                        <td className='table_email_column_width'>
                          {investor?.is_verified ? (
                            <CheckCircleIcon classes={['mr-4']} height='13px' />
                          ) : (
                            <ReportIcon classes={['mr-4']} height='13px' />
                          )}{' '}
                          {investor.email}
                        </td>
                      </ToolTip>
                      <td>{investor.account_status}</td>
                      <td>{investor.is_nda_accepted ? 'Accepted' : 'Pending'}</td>
                      <td>
                        {investor?.status ? kycStatusMap[investor.status] : kycStatusMap.PENDING}
                      </td>
                      <td>
                        {displayDollar(
                          investor.total_invested_amount
                            ? investor.total_invested_amount?.toString()
                            : '',
                          'USD',
                        )}
                      </td>
                      <td className='table_column_width'>
                        <span>
                          {displayDollar(
                            investor.investment_limit ? investor.investment_limit.toString() : '',
                            'USD',
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <EditIcon
                            classes={['align-center', 'pointer']}
                            height='16px'
                            width='16px'
                            onClick={() => {
                              setInvestorData({
                                ...investor,
                                investment_limit: String(Number(investor.investment_limit) / 100),
                              })
                              handleCloseModal()
                            }}
                          />
                        </span>
                      </td>
                      <td>
                        <EditIcon
                          classes={['align-center', 'pointer']}
                          height='24px'
                          width='24px'
                          onClick={() => navigate(`/investor/${investor.investor_id}/profile`)}
                        />
                      </td>
                      <td>
                        <EditIcon
                          classes={['align-center', 'pointer']}
                          height='24px'
                          width='24px'
                          onClick={() => handleUserInvestmentsClick(investor.investor_id)}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Flex>
          {breakpoint && breakpoint === SM ? null : (
            <Pagination
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
              pagination={pagination}
            />
          )}
        </div>
      ) : (
        <Flex variant={'column-center'} classes={['mt-12']}>
          <Span classes={['center']} variant={'thin'} text='No Investors Found' />
        </Flex>
      )}

      <Flex variant='column' classes={['mt-88', 'w-100']}>
        <H2 text='Recent bulk documents upload status' />
        <Flex classes={['column']}>
          <Span
            classes={['mt-8']}
            text='The tables show the documents which are could not be uploaded  and reason of the error in the format or content'
          />
        </Flex>
        {jobs && jobs.length > 0 && (
          <div className='w-100'>
            <Flex variant='row' classes={['mb-24', 'table_scroll_horizontal']}>
              <table className={'styled-table'}>
                <thead>
                  <tr>
                    <th>Investor email in the document</th>
                    <th>Uploaded at</th>
                    <th>Reason of failure</th>
                    <th>Failed quarterly report document</th>
                  </tr>
                </thead>
                <tbody>
                  {jobs.map((job: Jobs, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{job.email}</td>
                        <td>{new Date(job.createdAt).toLocaleString()}</td>
                        <td>{job.status}</td>
                        <td>
                          <EyeIcon
                            classes={['align-center', 'pointer']}
                            height='24px'
                            width='24px'
                            onClick={async () => {
                              const url = await handleFilePreview(job.fileId)
                              window.open(url, '_blank')
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Flex>
          </div>
        )}
      </Flex>
    </Flex>
  )
}

export { InvestorList }
