import { createSlice } from '@reduxjs/toolkit'
import { gbcUserState } from '../lib/types'
import {
  setGbcUserDataToLS,
  removeGbcUserDataFromLS,
  getGbcUserDataFromLS,
} from '../lib/utils/local-storage'

const initialData = {
  authToken: '',
  email: '',
  userId: 0,
  role: '',
  isEmailVerified: false,
}

const getInitialData = () => {
  const { authToken, gbcUserData } = getGbcUserDataFromLS()
  if (!!authToken && !!gbcUserData) {
    return {
      authToken,
      email: gbcUserData.email,
      userId: gbcUserData.userId,
      role: gbcUserData.role,
      isEmailVerified: gbcUserData.isEmailVerified,
    }
  }
  return initialData
}
const initialState = getInitialData()

const gbcUserSlice = createSlice({
  name: 'gbcUser',
  initialState,
  reducers: {
    createConfiguration: (state: gbcUserState, action: any) => {
      ;(state.authToken = action.payload.authToken),
        (state.email = action.payload.email),
        (state.userId = action.payload.userId),
        (state.role = action.payload.role),
        (state.isEmailVerified = action.payload.isEmailVerified)

      const { authToken, gbcUserData } = getGbcUserDataFromLS()
      if (!authToken && !gbcUserData) setGbcUserDataToLS(state)
    },
    resetConfiguration: () => {
      removeGbcUserDataFromLS()
      return { ...initialData }
    },
  },
})

export { gbcUserSlice }
export const { createConfiguration, resetConfiguration } = gbcUserSlice.actions
