/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { H2, LockResetIcon, Span, Button } from '../../lib/components'
import { VerifiedEmailProps } from '../../lib/types'
import { RESET_TEXT01, RESET_TEXT02, RESET_INSTRUCTIONS } from '../../lib/utils/message'
const ResetPasswordInstructions = ({ handleClick }: VerifiedEmailProps) => {
  return (
    <>
      <LockResetIcon />
      <H2 classes={['mt-32 mb-32']} text={RESET_INSTRUCTIONS} />
      <Span text={RESET_TEXT01} variant='default' />
      <Span text={RESET_TEXT02} variant='default' />
      <Button
        classes={['auto-btn-width', 'mt-40']}
        variant={'primary-btn'}
        text={'Home'}
        handleClick={handleClick}
      />
    </>
  )
}

export { ResetPasswordInstructions }
