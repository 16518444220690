import React from 'react'
import { Link } from 'react-router-dom'
import './anchor-component.scss'
import { AnchorProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

const Anchor = ({
  text,
  href,
  variant = 'basic-anchor',
  classes,
  isDisabled = false,
}: AnchorProps) => {
  return isDisabled ? (
    <Link
      className={`${variant} ${concatClassName(classes)}`}
      to={href}
      onClick={(event) => event.preventDefault()}
    >
      {text}
    </Link>
  ) : (
    <Link className={`${variant} ${concatClassName(classes)}`} to={href}>
      {text}
    </Link>
  )
}

const AnchorLabel = ({
  text,
  href,
  variant = 'label-anchor',
  classes,
  IconComponent,
  isDisabled = false,
}: AnchorProps) => {
  return isDisabled ? (
    <Link
      className={`${variant} ${concatClassName(classes)}`}
      to={href}
      onClick={(event) => event.preventDefault()}
    >
      {IconComponent} <span>{text}</span>{' '}
    </Link>
  ) : (
    <Link
      className={`${variant} ${concatClassName(classes)}`}
      target='_blank'
      to={''}
      onClick={(event) => {
        event.preventDefault()
        window.open(href)
      }}
    >
      {' '}
      {IconComponent} <span>{text}</span>{' '}
    </Link>
  )
}

export { Anchor, AnchorLabel }
