import React from 'react'
import { TagProps } from '../../types'
import { concatClassName } from '../../utils/helpers'
import './tag-component.scss'

const Tag = ({ variant = 'black-bg', text, classes }: TagProps) => {
  return (
    <div className={`tag-container ${variant} ${concatClassName(classes)}`}>
      <p>{text}</p>
    </div>
  )
}

export { Tag }
