import { axiosInstance } from '.'
import { removeEmptyValueFromObject } from '../lib/utils/helpers'
import { SEARCH_QUERIES_V1 } from './endpoints'

export const queriesServices = {
  searchQueries: async (params: any, details: { [key: string]: string | number }) => {
    try {
      const response = await axiosInstance.get(SEARCH_QUERIES_V1, {
        params: removeEmptyValueFromObject(params),
        headers: details,
      })
      return {
        pagination: {
          count: response.data.count,
          limit: response.data.limit,
          offset: response.data.offset,
          total: response.data.total,
        },
        data: response.data.queries,
        status: 200,
      }
    } catch (error: any) {
      return error.response
    }
  },
}
