import React from 'react'
import { ToggleProps } from '../../types'
import { concatClassName } from '../../utils/helpers'
import './toggle-component.scss'

const Toggle = ({ checked = false, classes, handleChange, isDisable = false }: ToggleProps) => {
  return (
    <label className={`switch ${concatClassName(classes)}`}>
      <input type='checkbox' checked={checked} onChange={handleChange} disabled={isDisable} />
      <span className='slider round'></span>
    </label>
  )
}

export { Toggle }
