/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import './project-detail-container.scss'
import { Flex, CTABanner, Button } from '../../lib/components'
import { ProjectDetailTop } from './project-detail-top'
import { AboutCompany } from './about-company'
import { ClientEngagements } from './client-engagements'
import { CompanyCard } from './company-card'
import { DealContours } from './deal-contours'
import { QUESTION_FOR_PROJECT, GET_IN_TOUCH_WITH_OWNER, CONTACT_US } from '../../lib/utils/message'
import { ProjectDetailPresenterProps } from '../../lib/types'

const ProjectDetailPresenter = ({
  project,
  investHandleClick,
  viewDocumentHandleClick,
}: ProjectDetailPresenterProps) => {
  return (
    <>
      {project && (
        <>
          <Flex classes={['ml-68']}>
            <ProjectDetailTop project={project} investHandleClick={investHandleClick} />
          </Flex>

          <Flex classes={['mt-36', 'ml-68', 'detail-layout']}>
            <Flex variant='column' classes={['company-details']}>
              <AboutCompany aboutCompany={project?.companyDetails || ''} />
              {!project?.isHideCompanyDetails && (
                <ClientEngagements
                  clientEngagement1={project?.clientEngagement1}
                  clientEngagement2={project?.clientEngagement2}
                  clientEngagement3={project?.clientEngagement3}
                  clientEngagement4={project?.clientEngagement4}
                />
              )}
              {!project?.isHideCompanyDetails && (
                <DealContours dealContoursListData={project?.dealContours || []} />
              )}
            </Flex>
            {!project?.isHideCompanyDetails && (
              <CompanyCard
                project={project}
                investHandleClick={investHandleClick}
                viewDocumentHandleClick={viewDocumentHandleClick}
              />
            )}
          </Flex>

          <Flex variant='row-center' classes={['mt-88']}>
            <CTABanner
              heading={QUESTION_FOR_PROJECT}
              subHeading={GET_IN_TOUCH_WITH_OWNER}
              ButtonComponent={
                <Button
                  classes={['auto-btn-width']}
                  variant='primary-btn'
                  text={CONTACT_US}
                  handleClick={() => {}}
                />
              }
            />
          </Flex>
        </>
      )}
    </>
  )
}

export { ProjectDetailPresenter }
