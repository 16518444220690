/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { Button, Input, GoogleReCaptcha, H2, Span } from '../../lib/components'
import { SyncLockResetIcon } from '../../lib/components/Icon'
import { IFormProps } from '../../lib/types'
import { FormikProps, Formik } from 'formik'
import {
  CHANGE_PASSWORD_TEXT01,
  CHANGE_PASSWORD_TEXT02,
  CHANGE_PASSWORD,
} from '../../lib/utils/message'

const ResetPasswordPresenter = ({
  initialValues,
  handleFormSubmit,
  validationSchema,
  reRef,
}: IFormProps<
  { password: string; passwordConfirmation: string },
  { password: string; passwordConfirmation: string }
>) => {
  return (
    <>
      <SyncLockResetIcon />
      <H2 classes={['mt-32 mb-32']} text={CHANGE_PASSWORD} />
      <Span text={CHANGE_PASSWORD_TEXT01} variant='default' />
      <Span text={CHANGE_PASSWORD_TEXT02} variant='default' />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(formik) => {
          const {
            values,
            touched,
            errors,
            dirty,
            handleChange,
            handleSubmit,
            setFieldValue,
          }: FormikProps<typeof initialValues> = formik
          return (
            <form className='w-80 mt-32'>
              <Input
                labelText='New password'
                name='password'
                placeholder='********'
                type='password'
                required
                value={values.password}
                handleChange={handleChange(initialValues.password)}
                dirty={dirty}
                errors={{
                  password: !!errors && errors.password ? errors.password : null,
                }}
                touched={{ password: !!touched && !!touched.password }}
              />

              <div className='mt-16'>
                <Input
                  labelText='Re-enter your new password'
                  name='passwordConfirmation'
                  placeholder='********'
                  type='password'
                  required
                  value={values.passwordConfirmation}
                  handleChange={handleChange(initialValues.passwordConfirmation)}
                  dirty={dirty}
                  errors={{
                    passwordConfirmation:
                      !!errors && errors.passwordConfirmation ? errors.passwordConfirmation : null,
                  }}
                  touched={{
                    passwordConfirmation: !!touched && !!touched.passwordConfirmation,
                  }}
                />
              </div>

              <GoogleReCaptcha
                handleChange={(value) => setFieldValue('reCaptchaToken', value)}
                reCaptchaRef={reRef}
                size='invisible'
              />

              <Button
                classes={['mt-16']}
                variant={'primary-btn'}
                text={CHANGE_PASSWORD}
                handleClick={handleSubmit}
              />
            </form>
          )
        }}
      </Formik>
    </>
  )
}

export { ResetPasswordPresenter }
