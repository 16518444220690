import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { InvestmentProjectListPresenter } from './investment-project-list-presenter'
import { investmentServices, investorServices, projectServices } from '../../services'
import { InvestmentSearchParams } from '../../lib/types'
import { showBanner } from '../../global-state/banner-slice'
import { getFileKeyFromSignedUrl } from '../../lib/utils/helpers'
import { useDispatch } from 'react-redux'

function InvestmentProjectListContainer() {
  const dispatch = useDispatch()
  const { investorId } = useParams()
  const [user, setUser] = useState()
  const [investments, setInvestments] = useState([])
  const [searchParams, setSearchParams] = useState<InvestmentSearchParams>({
    limit: '10',
    offset: '0',
    investor_id: investorId,
  })
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    count: 5,
    offset: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (investorId) {
      investorServices.getInvestorProfile(parseInt(investorId)).then((response) => {
        if (response) {
          setUser(response)
        }
      })
    }
  }, [investorId])

  useEffect(() => {
    const fetchAllInvestments = async () => {
      const data = await investmentServices.getInvestments(searchParams)
      if (data.status === 200) {
        setInvestments(data.investments)
        setPagination(data.pagination)
      }
    }
    fetchAllInvestments()
  }, [currentPage, searchParams])

  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    const { limit } = pagination
    setSearchParams({
      ...searchParams,
      offset: '0',
      limit: limit.toString(),
      [name]: value,
    })
    setCurrentPage(1)
    setPagination({ ...pagination, offset: 0 })
  }

  const resetSearchParams = async () => {
    setSearchParams({
      limit: '10',
      offset: '0',
      keyword: '',
      filterBy: '',
      sortBy: '',
      investor_id: investorId,
    })
  }

  const handleCurrentPage = (currentPage: number) => {
    const { limit } = pagination
    setCurrentPage(currentPage)
    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })
    setPagination({ ...pagination, offset: (currentPage - 1) * limit })
  }

  const handleFilePreview = async (fileKey: string) => {
    const signedUrl = getFileKeyFromSignedUrl(fileKey)
    const data = await projectServices.previewFile(signedUrl)
    if (data && data.data && data.status === 200) return data.data.url
    else {
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong preview document',
          variant: 'error-banner',
        }),
      )
      return ''
    }
  }

  return (
    <InvestmentProjectListPresenter
      user={user}
      pagination={pagination}
      handleCurrentPage={handleCurrentPage}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      resetSearchParams={resetSearchParams}
      searchParams={searchParams}
      handleSearchParams={handleSearchParams}
      investments={investments}
      handleFilePreview={handleFilePreview}
    />
  )
}

export { InvestmentProjectListContainer }
