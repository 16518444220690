import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { InvestmentUserListPresenter } from './investment-user-list-presenter'
import { investmentServices, projectServices } from '../../services'
import { InvestmentSearchParamsV1 } from '../../lib/types'
import { useDispatch } from 'react-redux'
import {
  getFileKeyFromSignedUrl,
  isValidFileSize,
  isValidImageType,
  isValidDocType,
} from '../../lib/utils/helpers'
import { showBanner } from '../../global-state/banner-slice'

function InvestmentUserListContainer() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { projectId } = useParams()
  const [project, setProject] = useState()
  const [investments, setInvestments] = useState([])
  const [searchParams, setSearchParams] = useState<InvestmentSearchParamsV1>({
    limit: '10',
    offset: '0',
    opportunity_id: projectId,
  })
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    count: 5,
    offset: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [fundAllocationFiles, setFundAllocationFiles] = useState([])

  useEffect(() => {
    if (projectId) {
      // projectServices.getProjectById(projectId, false).then((response) => {
      //   if (response.status === 200) {
      //     setProject(response.data);
      //   }
      // });
      projectServices.getOpportunityById(projectId, false).then((response) => {
        if (response.status === 200) {
          setProject(response.data)
        }
      })
    }
  }, [projectId])

  useEffect(() => {
    const fetchAllInvestments = async () => {
      const data = await investmentServices.getInvestments(searchParams)
      if (data.status === 200) {
        setInvestments(data.investments)
        setPagination(data.pagination)
      }
    }
    fetchAllInvestments()
  }, [currentPage, searchParams])

  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    const { limit } = pagination
    setSearchParams({
      ...searchParams,
      offset: '0',
      limit: limit.toString(),
      [name]: value,
    })
    setCurrentPage(1)
    setPagination({ ...pagination, offset: 0 })
  }

  const resetSearchParams = async () => {
    setSearchParams({
      limit: '10',
      offset: '0',
      keyword: '',
      filterBy: '',
      sortBy: '',
      opportunity_id: projectId,
    })
  }

  const handleCurrentPage = (currentPage: number) => {
    const { limit } = pagination
    setCurrentPage(currentPage)
    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })
    setPagination({ ...pagination, offset: (currentPage - 1) * limit })
  }

  const handleInvestorClick = (investorId: number) => {
    navigate(`/investor/${investorId}/profile`)
  }

  const handleFilePreview = async (fileKey: string) => {
    const signedUrl = getFileKeyFromSignedUrl(fileKey)
    const data = await projectServices.previewFile(signedUrl)
    if (data && data.data && data.status === 200) return data.data.url
    else {
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong preview document',
          variant: 'error-banner',
        }),
      )
      return ''
    }
  }

  const handleFileUpload = async (fileData: File, fileType: 'image' | 'doc') => {
    const isValidImage =
      fileType === 'image' ? isValidImageType(fileData) : isValidDocType(fileData)
    const isValidSize = isValidFileSize(fileData, fileType)
    if (isValidImage && isValidSize) {
      const data: any = await projectServices.postUploadFiles(fileData)
      if (data) return data
    } else {
      dispatch(
        showBanner({
          text: 'File size or type is incorrect for field',
          variant: 'error-banner',
        }),
      )
    }
  }

  const handleFundAllocationFiles = async (fileData: any) => {
    const promises = fileData.map(async (file: File) => {
      const data = await handleFileUpload(file, 'doc')
      if (data && data.data) {
        return data.data.Key
      }
      return undefined
    })
    const keys = await Promise.all(promises)
    const files = keys.filter((k) => k)
    if (files) {
      const payload = {
        projectId,
        fileIds: files,
      }
      const data: any = await projectServices.postUploadFundAllocationFiles(payload)
      if (data.status === 200)
        dispatch(
          showBanner({
            text: 'Fund allocation files are being processed by the system in the background. You will get a notification on the email once processing is completed',
            variant: 'success-banner',
          }),
        )
      else
        dispatch(
          showBanner({
            text: (data.data && data.data.message) || 'Something went wrong preview document',
            variant: 'error-banner',
          }),
        )
    }
  }

  return (
    <InvestmentUserListPresenter
      project={project}
      pagination={pagination}
      handleCurrentPage={handleCurrentPage}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      resetSearchParams={resetSearchParams}
      searchParams={searchParams}
      handleSearchParams={handleSearchParams}
      investments={investments}
      handleInvestorClick={handleInvestorClick}
      handleFilePreview={handleFilePreview}
      handleFileUpload={handleFileUpload}
      handleFundAllocationFiles={handleFundAllocationFiles}
      setFundAllocationFiles={setFundAllocationFiles}
      fundAllocationFiles={fundAllocationFiles}
    />
  )
}

export { InvestmentUserListContainer }
