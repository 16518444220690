import { axiosInstance } from '.'
import { InvestmentSearchParams, QuarterlyReportProps } from '../lib/types'
import { removeEmptyValueFromObject } from '../lib/utils/helpers'
import {
  GET_INVESTMENT_DETAIL,
  UPDATE_INVESTMENT_STATUS,
  GET_INVESTMENT_DETAIL_FOR_INVESTOR,
  UPLOAD_QUARTERLY_REPORT,
  SEARCH_INVESTMENTS_V1,
  GET_INVESTMENT_DETAIL_FOR_INVESTOR_V1,
  GET_INVESTMENT_BY_ID,
  UPDATE_INVESTMENT_STATUS_V1,
  SET_INVESTMENT_LIMIT_V1,
} from './endpoints'

export const investmentServices = {
  getInvestments: async (params: InvestmentSearchParams) => {
    try {
      // const data = await axiosInstance.get(SEARCH_INVESTMENTS, {
      //   params: removeEmptyValueFromObject(params),
      // });
      const data = await axiosInstance.get(SEARCH_INVESTMENTS_V1, {
        params: removeEmptyValueFromObject(params),
      })
      return {
        investments: data.data.investments,
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        status: data.status,
      }
    } catch (error: any) {
      return error.response
    }
  },
  getInvestmentDetail: async (investmentId: string) => {
    try {
      const data = await axiosInstance.get(`${GET_INVESTMENT_DETAIL}/${investmentId}`)
      return data
    } catch (error: any) {
      return error.response
    }
  },
  getInvestmentDetailForInvestor: async (investorId: string) => {
    try {
      // const data = await axiosInstance.get(GET_INVESTMENT_DETAIL_FOR_INVESTOR, {
      //   params: { userId: investorId },
      // });
      const data = await axiosInstance.get(
        GET_INVESTMENT_DETAIL_FOR_INVESTOR_V1(Number(investorId)),
      )
      return data
    } catch (error: any) {
      return error.response
    }
  },
  uploadQuarterlyReportForInvestor: async (payload: QuarterlyReportProps) => {
    try {
      const data = await axiosInstance.post(UPLOAD_QUARTERLY_REPORT, payload)
      return data
    } catch (error: any) {
      return error.response
    }
  },
  // v1
  getInvestmentById: async (id: string) => {
    try {
      const data = await axiosInstance.get(`${GET_INVESTMENT_BY_ID}/${id}`)
      const opportunity = {
        ...data.data,
      }

      return { data: opportunity, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },
  updateInvestmentStatus: async (payload: any) => {
    try {
      const updatePayload = {
        id: Number(payload.investmentId),
        status: payload.investmentStatus,
        reason: payload.reason ?? '',
      }
      const data = await axiosInstance.patch(
        UPDATE_INVESTMENT_STATUS_V1,
        removeEmptyValueFromObject(updatePayload),
      )
      return data
    } catch (error: any) {
      return error.response
    }
  },
  setInvestmentLimit: async (investorId: number, investmentLimit: number) => {
    try {
      const payload = {
        investorId,
        investmentLimit,
      }
      const data: any = await axiosInstance.patch(SET_INVESTMENT_LIMIT_V1, payload)
      return { ...data }
    } catch (error: any) {
      return error.response
    }
  },
}
