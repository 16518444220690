import React, { useEffect } from 'react'
import './banner-component.scss'
import { useSelector } from 'react-redux'
import { WarningIcon, SuccessIcon, ErrorIcon } from '../Icon'
import { RootState, store } from '../../../store'
import { showBannerTimeout } from '../../../global-state/banner-slice'

const Banner = () => {
  const { show, text, variant } = useSelector((state: RootState) => state.banner)
  useEffect(() => {
    if (show) {
      store.dispatch(showBannerTimeout())
    }
  }, [show])
  return (
    <>
      {show && (
        <div className={`banner-container ${variant}`}>
          {variant === 'warning-banner' && <WarningIcon type={variant} />}
          {variant === 'success-banner' && <SuccessIcon />}
          {variant === 'error-banner' && <ErrorIcon />}
          <span>{text}</span>
        </div>
      )}
    </>
  )
}

export { Banner }
