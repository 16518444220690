import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from './store'
interface PrivateRouteProps {
  children: React.ReactNode
}
interface PublicRouteProps {
  children: React.ReactNode
}

//these values should be part of the gbcUser configuration and should be fetched from there

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { authToken } = useSelector((state: RootState) => state.gbcUser)
  const isLoggedIn = authToken && authToken
  if (!isLoggedIn) return <Navigate to='/login' replace />
  return <>{children}</>
}

const PublicRoute = ({ children }: PublicRouteProps) => {
  const { authToken } = useSelector((state: RootState) => state.gbcUser)
  const isLoggedIn = authToken && authToken
  if (!isLoggedIn) return <>{children}</>
  return <Navigate to='/opportunities' replace />
}

export { PrivateRoute, PublicRoute }
