import React, { useState, useEffect } from 'react'
import { ProjectFormPresenter } from './project-form-presenter'
import { opportunitySchema } from '../../lib/utils/schema'
import { NewOpportunity } from '../../lib/types'
import { projectServices } from '../../services'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { showBanner } from '../../global-state/banner-slice'
import { useNavigate, useParams } from 'react-router-dom'
import { opportunityInitialValues } from './project-initialValues'
import {
  isValidFileSize,
  isValidImageType,
  isValidDocType,
  isValidVideoType,
  createSlug,
} from '../../lib/utils/helpers'

const ProjectFormContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { id, projectSlug } = params
  const { authToken } = useSelector((state: RootState) => state.gbcUser)
  const [initialValues, setInitialValues] = useState<NewOpportunity>(opportunityInitialValues)

  useEffect(() => {
    const fetchProjectById = async () => {
      const data: any = await projectServices.getOpportunityById(id as string, true)
      if (data && data.data && data.status === 200) {
        const opportunityData = { ...data.data }

        if (opportunityData.images.length === 0) {
          opportunityData.images = opportunityInitialValues.images
        }

        if (opportunityData.highlights.length === 0) {
          opportunityData.highlights = opportunityInitialValues.highlights
        }

        if (opportunityData.key_factors.length === 0) {
          opportunityData.key_factors = opportunityInitialValues.key_factors
        }

        if (opportunityData.documents.length === 0) {
          opportunityData.documents = opportunityInitialValues.documents
        }
        setInitialValues(opportunityData)
      }
    }
    if (id && projectSlug !== 'undefined') fetchProjectById()
    else setInitialValues(opportunityInitialValues)
  }, [id, projectSlug])

  const handleSubmit = async (project: NewOpportunity) => {
    if (project.investment_return.toString() === '') project.investment_return = 0

    if (!id && !projectSlug) {
      const projectData: any = await projectServices.createOpportunity(project)
      if (!!projectData && projectData.status === 200) {
        dispatch(
          showBanner({
            text: 'Opportunity created successfully',
            variant: 'success-banner',
          }),
        )
        navigate('/opportunities')
      } else {
        dispatch(
          showBanner({
            text:
              (projectData.data && projectData.data.message) ||
              'Something went wrong! Please try again.',
            variant: 'error-banner',
          }),
        )
      }
    } else {
      const projectData: any = await projectServices.updateOpportunity(project, id as string)
      if (!!projectData && projectData.status === 200) {
        dispatch(
          showBanner({
            text: 'Opportunity updated successfully',
            variant: 'success-banner',
          }),
        )
      } else {
        dispatch(
          showBanner({
            text:
              (projectData.data && projectData.data.message) ||
              'Something went wrong! Please try again.',
            variant: 'error-banner',
          }),
        )
      }
    }
  }

  const handleFileUpload = async (fileData: File, fileType: 'image' | 'doc' | 'video') => {
    const isValidType = () => {
      switch (fileType) {
        case 'doc':
          return isValidDocType(fileData)
        case 'video':
          return isValidVideoType(fileData)
        case 'image':
        default:
          return isValidImageType(fileData)
      }
    }
    const isValidSize = isValidFileSize(fileData, fileType)
    if (isValidType() && isValidSize) {
      const data: any = await projectServices.postUploadFiles(fileData)
      if (data) return data
    } else {
      dispatch(
        showBanner({
          text: 'File size or type is incorrect for field',
          variant: 'error-banner',
        }),
      )
    }
  }
  const handleErrorMessage = (val: string) =>
    dispatch(showBanner({ text: val, variant: 'error-banner' }))

  return (
    <ProjectFormPresenter
      initialValues={initialValues}
      validationSchema={opportunitySchema}
      handleFormSubmit={handleSubmit}
      handleFileUpload={handleFileUpload}
      handleErrorMessage={handleErrorMessage}
    />
  )
}

export { ProjectFormContainer }
