import React from 'react'

const UploadedImage = ({
  published,
  fileUrl,
  id,
}: {
  published: boolean
  fileUrl: string
  id: string
}) => {
  return (
    <div className='image-container-wrapper'>
      <label className='upload-img-cont' htmlFor={id}>
        {published ? null : <i className='fa fa-plus-circle remove-img-icon' />}
        {fileUrl ? <img src={fileUrl} alt='project-img' className='project-image' /> : null}
      </label>
    </div>
  )
}

export { UploadedImage }
