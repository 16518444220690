import React from 'react'
import { ILoginForm } from '../../lib/types'
import { FormikProps, Formik } from 'formik'
import {
  Input,
  Button,
  Line,
  Anchor,
  Flex,
  CheckboxInput,
  GoogleReCaptcha,
} from '../../lib/components'

const LoginPresenter = ({
  initialValues,
  handleFormSubmit,
  validationSchema,
  reRef,
}: ILoginForm<{ email: string; password: string; rememberMe: string }>) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {(formik) => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleChange,
          handleSubmit,
          setFieldValue,
        }: FormikProps<typeof initialValues> = formik
        return (
          <form>
            <Input
              labelText='Email'
              name='email'
              placeholder='Enter your email'
              type='text'
              required
              value={values.email}
              handleChange={handleChange(initialValues.email)}
              dirty={dirty}
              errors={{ email: !!errors && errors.email ? errors.email : null }}
              touched={{ email: !!touched && !!touched.email }}
            />

            <Input
              labelText='Password'
              name='password'
              placeholder='********'
              required
              type='password'
              value={values.password}
              handleChange={handleChange(initialValues.password)}
              dirty={dirty}
              errors={{
                password: !!errors && errors.password ? errors.password : null,
              }}
              touched={{ password: !!touched && !!touched.password }}
            />
            <Flex variant='space-between'>
              <CheckboxInput
                labelText='Remember me'
                name='rememberMe'
                value={values.rememberMe ? 'true' : 'false'}
                handleChange={handleChange(initialValues.rememberMe)}
              />
              <Anchor text='Forgot your password?' href='/forgot-password' variant='basic-anchor' />
            </Flex>
            <Line />

            <GoogleReCaptcha
              handleChange={(value) => setFieldValue('reCaptchaToken', value)}
              reCaptchaRef={reRef}
              size='invisible'
            />

            <Button text='Log In' variant='primary-btn' handleClick={handleSubmit} />
          </form>
        )
      }}
    </Formik>
  )
}

export { LoginPresenter }
