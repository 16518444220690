import React, { useCallback } from 'react'
import { InputProps, CheckboxProps, TextAreaProps } from '../../types'
import './input-component.scss'
import { ERROR, SUCCESS } from '../../utils/message'
import { StatusLabel } from '../StatusLabel'
import { concatClassName } from '../../utils/helpers'
import { CheckCircleIcon, ReportIcon } from '../Icon/icon-component'

const Input = ({
  type,
  labelText,
  name,
  value,
  handleChange,
  required,
  disabled = false,
  placeholder,
  errors,
  touched,
  infoText,
  dirty,
  classes,
  ...props
}: InputProps) => {
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const getClassName = useCallback(() => {
    if (isValid) return 'success-input input-field'
    else if (isError) return 'error-input input-field'
    else return 'input-field'
  }, [errors])

  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])
  return (
    <>
      {labelText && <div className='input-label'> {labelText}</div>}
      <input
        name={name}
        value={value}
        onChange={handleChange}
        className={`${getClassName()} ${concatClassName(classes)}`}
        placeholder={placeholder}
        type={type}
        {...props}
        required={required}
        disabled={disabled}
      />
      <span className='input-icon'>
        {isValid && <CheckCircleIcon height='20' width='20' type={getMessageType()} />}
        {isError && <ReportIcon height='20' width='20' type={getMessageType()} />}
      </span>
      {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
      {!isError && infoText && <StatusLabel type='info' variant='basic' text={infoText} />}
    </>
  )
}

const CheckboxInput = ({
  name,
  labelText,
  value,
  handleChange,
  errors,
  touched,
  dirty,
}: CheckboxProps) => {
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])
  return (
    <>
      <div className='input-checkbox-container'>
        {
          <input
            className='input-checkbox'
            checked={value === 'true' ? true : false}
            name={name}
            type={'checkbox'}
            value={value}
            onChange={handleChange}
          />
        }
        <span className='input-checkbox-label'> {labelText} </span>
      </div>
      <span className='input-icon'>
        {isValid && <CheckCircleIcon height='20' width='20' type={getMessageType()} />}
        {isError && <ReportIcon height='20' width='20' type={getMessageType()} />}
      </span>
      {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
    </>
  )
}

const TextboxInput = ({
  labelText,
  name,
  value,
  handleChange,
  required,
  placeholder,
  errors,
  touched,
  infoText,
  dirty,
}: TextAreaProps) => {
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const getClassName = useCallback(() => {
    if (isValid) return 'success-input input-field'
    else if (isError) return 'error-input input-field'
    else return 'input-field'
  }, [errors])

  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])
  return (
    <>
      {labelText && <div className='input-label'> {labelText}</div>}
      <textarea
        placeholder={placeholder}
        required={required}
        className={getClassName()}
        name={name}
        value={value}
        onChange={handleChange}
      />
      <span className='input-icon'>
        {isValid && <CheckCircleIcon height='20' width='20' type={getMessageType()} />}
        {isError && <ReportIcon height='20' width='20' type={getMessageType()} />}
      </span>
      {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
      {!isError && infoText && <StatusLabel type='info' variant='basic' text={infoText} />}
    </>
  )
}
export { Input, CheckboxInput, TextboxInput }
