import { projectDetails, NewOpportunity } from '../../lib/types'

export const projectInitialValues: projectDetails = {
  projectName: '',
  projectSlug: '',
  closingDate: new Date(),
  irr: '',
  ticketSize: '',
  projectSummary: '',
  valuation: '',
  companyWebsite: '',
  location: 'Afghanistan',
  raiseGoal: '',
  overallRaiseGoal: '',
  dealContours: '',
  companyDetails: '',
  companyMilestones: '',
  clientEngagement1: '',
  clientEngagement2: '',
  clientEngagement3: '',
  clientEngagement4: '',
  keyInvestorsImg1: '',
  keyInvestorsImg2: '',
  keyInvestorsImg3: '',
  keyInvestorsImg4: '',
  keyInvestorsImg5: '',
  keyInvestorsName1: '',
  keyInvestorsName2: '',
  keyInvestorsName3: '',
  keyInvestorsName4: '',
  keyInvestorsName5: '',
  legalDocuments: '',
  isHighCommitenceProject: false,
  companyLogo: '',
  isHideCompanyDetails: false,
  companyName: '',
  projectCover: '',
  assetClass: 'Private Equity',
  isFeatured: false,
  isPublished: false,
  subscriptionAgreement: '',
  investmentTimelineStartDate: new Date(),
  investmentTimelineEndDate: new Date(),
  shipmentStatus: '',
  expectedShipmentCompletionDate: new Date(),
  plannedShipmentCompletionDate: new Date(),
  productVideoURL: '',
  productImages: [],
}

export const opportunityInitialValues: NewOpportunity = {
  opportunity_title: '',
  opportunity_slug: '',
  jurisdiction: '',
  currency_code: '',
  is_featured: false,
  asset_class: 'Private Equity',
  status: 'UNPUBLISHED',
  images: [''],
  video: '',
  opportunity_summary: '',
  short_opportunity_summary: '',
  raise_goal: 0,
  closing_date: new Date().toString(),
  investment_return: 0,
  ticket_size: 0,
  documents: [
    {
      title: '',
      url: '',
    },
  ],
  key_factors: [''],
  terms: {
    level1: {
      header: '',
      termObjects: [
        {
          title: '',
          content: '',
        },
      ],
    },
    level2: {
      header: '',
      termObjects: [
        {
          title: '',
          content: '',
        },
      ],
    },
  },
  highlights: [''],
  metadata: {
    company: {
      website: '',
      about: '',
    },
  },
  cover_image: '',
  coupon_payment_interval_in_months: 0,
}
