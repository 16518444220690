import { axiosInstance } from '.'
import { projectDetails, SearchParams, NewOpportunity } from '../lib/types'
import {
  UPLOAD_FILE,
  PREVIEW_FILE,
  CREATE_NEW_PROJECT,
  UPDATE_PROJECT,
  GET_ALL_PROJECTS,
  GET_PROJECT_BY_ID,
  DELETE_PROJECT,
  UPLOAD_FUND_ALLOCATION,
  UPLOAD_QUARTERLY_REPORT_JOB,
  SEARCH_OPPORTUNITIES,
  SEARCH_PROJECTS,
  GET_OPPORTUNITY_BY_ID,
  CREATE_NEW_OPPORTUNITY,
  UPDATE_OPPORTUNITY,
  GET_INVESTMENT_BY_ID,
} from './endpoints'
import {
  stringToArray,
  arrayToString,
  createSlug,
  sanitizeSignedUrlsForObject,
  removeEmptyValueFromObject,
  sanitizeOpportunityPayload,
} from '../lib/utils/helpers'

export const projectServices = {
  postProjectRequest: async (projectData: projectDetails) => {
    try {
      const payload = {
        ...projectData,
        companyMilestones: stringToArray(projectData.companyMilestones) || [],
        dealContours: stringToArray(projectData.dealContours) || [],
        closingDate: projectData.closingDate?.toISOString(),
        projectSlug: createSlug(projectData.projectName),
        overallRaiseGoal: parseFloat(projectData.overallRaiseGoal) * 100 || 0,
        raiseGoal: parseFloat(projectData.raiseGoal) * 100 || 0,
        ticketSize: parseFloat(projectData.ticketSize) * 100 || 0,
        valuation: parseFloat(projectData.valuation) * 100 || 0,
        irr: parseFloat(projectData.irr) || 0,
        shipmentStatus:
          projectData.assetClass === 'Trade Finance' ? [projectData.shipmentStatus] : '',
      }
      const sanitizedPayload = sanitizeSignedUrlsForObject(
        [
          'projectCover',
          'clientEngagement1',
          'clientEngagement2',
          'clientEngagement3',
          'clientEngagement4',
          'companyLogo',
          'subscriptionAgreement',
        ],
        payload,
      )
      const data: any = await axiosInstance.post(CREATE_NEW_PROJECT, sanitizedPayload)
      const project = {
        ...data.data,
        companyMilestones:
          (data.data &&
            data.data.companyMilestones &&
            arrayToString(data.data.companyMilestones)) ||
          '',
        dealContours:
          (data.data && data.data.dealContours && arrayToString(data.data.dealContours)) || '',
        closingDate:
          (data.data && data.data.closingDate && new Date(data.data.closingDate)) || new Date(),
      }
      return { data: project, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },
  postUploadFiles: async (filesData: File) => {
    try {
      const formData = new FormData()
      formData.append('file', filesData)
      return await axiosInstance.post(UPLOAD_FILE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (error: any) {
      return error.response
    }
  },
  postUploadFundAllocationFiles: async (filesData: any) => {
    try {
      return await axiosInstance.post(UPLOAD_FUND_ALLOCATION, filesData)
    } catch (error: any) {
      return error.response
    }
  },
  postQuarterlyReportFiles: async (filesData: any) => {
    try {
      return await axiosInstance.post(UPLOAD_QUARTERLY_REPORT_JOB, filesData)
    } catch (error: any) {
      return error.response
    }
  },
  updateProjectRequest: async (projectData: projectDetails, id: string) => {
    try {
      const payload = {
        ...projectData,
        companyMilestones: stringToArray(projectData.companyMilestones) || [],
        dealContours: stringToArray(projectData.dealContours) || [],
        closingDate: projectData.closingDate?.toISOString(),
        projectSlug: createSlug(projectData.projectName),
        irr: parseFloat(projectData.irr) || 0,
        overallRaiseGoal: parseFloat(projectData.overallRaiseGoal) * 100 || 0,
        raiseGoal: parseFloat(projectData.raiseGoal) * 100 || 0,
        ticketSize: parseFloat(projectData.ticketSize) * 100 || 0,
        valuation: parseFloat(projectData.valuation) * 100 || 0,
      }
      const sanitizedPayload = sanitizeSignedUrlsForObject(
        [
          'projectCover',
          'clientEngagement1',
          'clientEngagement2',
          'clientEngagement3',
          'clientEngagement4',
          'companyLogo',
          'subscriptionAgreement',
        ],
        payload,
      )
      return await axiosInstance.post(`${UPDATE_PROJECT}/${id}`, sanitizedPayload)
    } catch (error: any) {
      return error.response
    }
  },
  getAllProjects: async () => {
    try {
      return await axiosInstance.get(GET_ALL_PROJECTS)
    } catch (error: any) {
      return error.response
    }
  },
  getProjectById: async (projectId: string, isFormik = true) => {
    try {
      const data = await axiosInstance.get(`${GET_PROJECT_BY_ID}/${projectId}`)
      let project
      if (isFormik) {
        project = {
          companyMilestones:
            (data.data &&
              data.data.companyMilestones &&
              arrayToString(data.data.companyMilestones)) ||
            '',
          dealContours:
            (data.data && data.data.dealContours && arrayToString(data.data.dealContours)) || '',
          closingDate:
            (data.data && data.data.closingDate && new Date(data.data.closingDate)) || new Date(),
          irr: (data.data && data.data.irr.toString()) || '',
          overallRaiseGoal: (data.data && (data.data.overallRaiseGoal / 100).toString()) || '',
          raiseGoal: (data.data && (data.data.raiseGoal / 100).toString()) || '',
          ticketSize: (data.data && (data.data.ticketSize / 100).toString()) || '',
          valuation: (data.data && (data.data.valuation / 100).toString()) || '',
          projectName: (data.data && data.data.projectName) || '',
          projectSlug: (data.data && data.data.projectSlug) || '',
          projectSummary: (data.data && data.data.projectSummary) || '',
          companyWebsite: (data.data && data.data.companyWebsite) || '',
          location: (data.data && data.data.location) || 'Afghanistan',
          companyDetails: (data.data && data.data.companyDetails) || '',
          clientEngagement1: (data.data && data.data.clientEngagement1) || '',
          clientEngagement2: (data.data && data.data.clientEngagement2) || '',
          clientEngagement3: (data.data && data.data.clientEngagement3) || '',
          clientEngagement4: (data.data && data.data.clientEngagement4) || '',
          legalDocuments: (data.data && data.data.legalDocuments) || '',
          isHighCommitenceProject: (data.data && data.data.isHighCommitenceProject) || false,
          companyLogo: (data.data && data.data.companyLogo) || '',
          isHideCompanyDetails: (data.data && data.data.isHideCompanyDetails) || false,
          companyName: (data.data && data.data.companyName) || '',
          projectCover: (data.data && data.data.projectCover) || '',
          assetClass: (data.data && data.data.assetClass) || 'Private Equity',
          isFeatured: (data.data && data.data.isFeatured) || false,
          isPublished: (data.data && data.data.isPublished) || false,
          subscriptionAgreement: (data.data && data.data.subscriptionAgreement) || '',
          keyInvestorsImg1: (data.data && data.data.keyInvestorsImg1) || '',
          keyInvestorsImg2: (data.data && data.data.keyInvestorsImg2) || '',
          keyInvestorsImg3: (data.data && data.data.keyInvestorsImg3) || '',
          keyInvestorsImg4: (data.data && data.data.keyInvestorsImg4) || '',
          keyInvestorsImg5: (data.data && data.data.keyInvestorsImg5) || '',
          keyInvestorsName1: (data.data && data.data.keyInvestorsName1) || '',
          keyInvestorsName2: (data.data && data.data.keyInvestorsName2) || '',
          keyInvestorsName3: (data.data && data.data.keyInvestorsName3) || '',
          keyInvestorsName4: (data.data && data.data.keyInvestorsName4) || '',
          keyInvestorsName5: (data.data && data.data.keyInvestorsName5) || '',
        }
      }
      if (!isFormik) {
        project = {
          ...data.data,
          isSaved: (data.data && data.data.isSaved) || false,
          committedAmount:
            (data.data.committedAmount && data.data.committedAmount.toString()) || '0',
          irr: (data.data && data.data.irr.toString()) || '',
          overallRaiseGoal: (data.data && data.data.overallRaiseGoal.toString()) || '',
          raiseGoal: (data.data && data.data.raiseGoal.toString()) || '',
          ticketSize: (data.data && data.data.ticketSize.toString()) || '',
          valuation: (data.data && data.data.valuation.toString()) || '',
        }
      }
      return { data: project, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },
  getSearchProject: async (params: SearchParams) => {
    try {
      const data = await axiosInstance.get(`${SEARCH_PROJECTS}`, {
        params: removeEmptyValueFromObject(params),
      })
      const searchedOpportunities = {
        opportunities: data.data.opportunities,
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        status: data.status,
      }
      return searchedOpportunities
    } catch (error: any) {
      return error.response
    }
  },

  deleteProject: async (id: number) => {
    try {
      const data = await axiosInstance.delete(`${DELETE_PROJECT}/${id}`)
      return data
    } catch (error: any) {
      return error.response
    }
  },
  previewFile: async (fileKey: string) => {
    try {
      const data = await axiosInstance.get(`${PREVIEW_FILE}/${fileKey}`)
      return data
    } catch (error: any) {
      return error.response
    }
  },

  //v1
  getSearchOpportunities: async (params: SearchParams) => {
    try {
      // const data = await axiosInstance.get( `${SEARCH_PROJECTS}`, {
      //     params: removeEmptyValueFromObject(params)
      // })
      const data = await axiosInstance.get(`${SEARCH_OPPORTUNITIES}`, {
        params: removeEmptyValueFromObject(params),
      })
      const searchedOpportunities = {
        opportunities: data.data.opportunities,
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        status: data.status,
      }
      return searchedOpportunities
    } catch (error: any) {
      return error.response
    }
  },

  getOpportunityById: async (id: string, isFormik = true) => {
    try {
      const data = await axiosInstance.get(`${GET_OPPORTUNITY_BY_ID}/${id}`)
      let project
      if (isFormik && data.data) {
        project = {
          ...data.data,
        }
      }
      if (!isFormik) {
        project = {
          ...data.data,
          committedAmount:
            (data.data.total_investment_amount && data.data.total_investment_amount.toString()) ??
            '0',
          irr: data.data.investment_return.toString() ?? '',
          overallRaiseGoal: data.data.raise_goal.toString() ?? '',
          raiseGoal: data.data.raise_goal.toString() ?? '',
          ticketSize: data.data.ticket_size.toString() ?? '',
        }
      }
      return { data: project, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },

  createOpportunity: async (opportunityDetailsData: NewOpportunity) => {
    try {
      const payload = sanitizeOpportunityPayload(opportunityDetailsData, [])

      const data: any = await axiosInstance.post(CREATE_NEW_OPPORTUNITY, payload)
      const opportunityData = {
        ...data.data,
      }
      return { data: opportunityData, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },

  updateOpportunity: async (opportunityDetailsData: NewOpportunity, id: string) => {
    try {
      const payload = sanitizeOpportunityPayload(opportunityDetailsData, [
        'id',
        'created_at',
        'updated_at',
        'no_of_investors',
        'total_investment_amount',
        'updates',
      ])

      return await axiosInstance.patch(`${UPDATE_OPPORTUNITY}/${id}`, payload)
    } catch (error: any) {
      return error.response
    }
  },
}
