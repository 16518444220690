/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import { H6, Line, Input, Flex, InputFile, Button } from '../../lib/components'
import { IFormProps, homeDetails } from '../../lib/types'
import { FormikProps, Formik } from 'formik'
import { UploadedImage } from '../project-form/uploaded-image'
import './home-form.scss'

const HomeFormPresenter = ({
  initialValues,
  handleFormSubmit,
  validationSchema,
  handleFileUpload,
  handleErrorMessage,
}: IFormProps<homeDetails, homeDetails>) => {
  const [imageUrl, setImageUrl] = useState({
    keyInvestorsImg1: '',
    keyInvestorsImg2: '',
    keyInvestorsImg3: '',
    keyInvestorsImg4: '',
    keyInvestorsImg5: '',
  })
  return (
    <>
      {' '}
      <div className='project-form-container'>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          handleFileUpload={handleFileUpload}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {(formik) => {
            const {
              values,
              touched,
              errors,
              dirty,
              handleChange,
              setFieldValue,
              handleSubmit,
            }: FormikProps<typeof initialValues> = formik
            return (
              <form>
                <H6 classes={['mt-28', 'mb-32']} text='Key Investors Details' />
                <Line />
                <Flex classes={['form-image-flex-direction']} variant='space-between'>
                  <Flex variant='column-center'>
                    <UploadedImage
                      published
                      fileUrl={imageUrl.keyInvestorsImg1 || values.keyInvestorsImg1}
                      id={'1'}
                    />
                    <InputFile
                      name='keyInvestorsImg1'
                      handleFiles={async (file: any) => {
                        if (handleFileUpload && file) {
                          const data = await handleFileUpload(file as File, 'image')
                          if (data && data.data) {
                            setFieldValue('keyInvestorsImg1', data.data.Key)
                            setImageUrl((state) => ({
                              ...state,
                              keyInvestorsImg1: data.data.url,
                            }))
                          } else handleErrorMessage && handleErrorMessage(data.message)
                        } else {
                          setFieldValue('keyInvestorsImg1', '')
                          setImageUrl((state) => ({
                            ...state,
                            keyInvestorsImg1: '',
                          }))
                        }
                      }}
                      label='Investor logo 1'
                      dirty={dirty}
                      errors={{
                        keyInvestorsImg1:
                          !!errors && errors.keyInvestorsImg1 ? errors.keyInvestorsImg1 : null,
                      }}
                      handleDeleteFile={() => {
                        setFieldValue('keyInvestorsImg1', '')
                        setImageUrl((state) => ({
                          ...state,
                          keyInvestorsImg1: '',
                        }))
                      }}
                      touched={{
                        keyInvestorsImg1: !!touched && !!touched.keyInvestorsImg1,
                      }}
                    />
                    <div className='key_investors_company_field'>
                      <Input
                        name='keyCompanyName'
                        labelText='Investor Name 1'
                        placeholder='Investor Name'
                        type='text'
                        required
                        value={values.keyInvestorsName1}
                        handleChange={(e) => setFieldValue('keyInvestorsName1', e.target.value)}
                        dirty={dirty}
                        errors={{
                          keyInvestorsName1:
                            !!errors && errors.keyInvestorsName1 ? errors.keyInvestorsName1 : null,
                        }}
                        touched={{
                          keyInvestorsName1: !!touched && !!touched.keyInvestorsName1,
                        }}
                      />
                    </div>
                  </Flex>
                  <Flex variant='column-center'>
                    <UploadedImage
                      published
                      fileUrl={imageUrl.keyInvestorsImg2 || values.keyInvestorsImg2}
                      id={'2'}
                    />
                    <InputFile
                      name='keyInvestorsImg2'
                      handleFiles={async (file: any) => {
                        if (handleFileUpload && file) {
                          const data = await handleFileUpload(file as File, 'image')
                          if (data && data.data) {
                            setFieldValue('keyInvestorsImg2', data.data.Key)
                            setImageUrl((state) => ({
                              ...state,
                              keyInvestorsImg2: data.data.url,
                            }))
                          } else handleErrorMessage && handleErrorMessage(data.message)
                        } else {
                          setFieldValue('keyInvestorsImg2', '')
                          setImageUrl((state) => ({
                            ...state,
                            keyInvestorsImg2: '',
                          }))
                        }
                      }}
                      label='Investor logo 2'
                      dirty={dirty}
                      errors={{
                        keyInvestorsImg1:
                          !!errors && errors.keyInvestorsImg2 ? errors.keyInvestorsImg2 : null,
                      }}
                      handleDeleteFile={() => {
                        setFieldValue('keyInvestorsImg2', '')
                        setImageUrl((state) => ({
                          ...state,
                          keyInvestorsImg2: '',
                        }))
                      }}
                      touched={{
                        keyInvestorsImg2: !!touched && !!touched.keyInvestorsImg2,
                      }}
                    />
                    <div className='key_investors_company_field'>
                      <Input
                        name='keyCompanyName'
                        labelText='Investor Name 2'
                        placeholder='Investor Name'
                        type='text'
                        required
                        value={values.keyInvestorsName2}
                        handleChange={(e) => setFieldValue('keyInvestorsName2', e.target.value)}
                        dirty={dirty}
                        errors={{
                          keyInvestorsName2:
                            !!errors && errors.keyInvestorsName2 ? errors.keyInvestorsName2 : null,
                        }}
                        touched={{
                          keyInvestorsName2: !!touched && !!touched.keyInvestorsName2,
                        }}
                      />
                    </div>
                  </Flex>
                </Flex>
                <Flex classes={['form-image-flex-direction']} variant='space-between'>
                  <Flex variant='column-center'>
                    <UploadedImage
                      published
                      fileUrl={imageUrl.keyInvestorsImg3 || values.keyInvestorsImg3}
                      id={'3'}
                    />
                    <InputFile
                      name='keyInvestorsImg3'
                      handleFiles={async (file: any) => {
                        if (handleFileUpload && file) {
                          const data = await handleFileUpload(file as File, 'image')
                          if (data && data.data) {
                            setFieldValue('keyInvestorsImg3', data.data.Key)
                            setImageUrl((state) => ({
                              ...state,
                              keyInvestorsImg3: data.data.url,
                            }))
                          } else handleErrorMessage && handleErrorMessage(data.message)
                        } else {
                          setFieldValue('keyInvestorsImg3', '')
                          setImageUrl((state) => ({
                            ...state,
                            keyInvestorsImg3: '',
                          }))
                        }
                      }}
                      label='Investor logo 3'
                      dirty={dirty}
                      errors={{
                        keyInvestorsImg3:
                          !!errors && errors.keyInvestorsImg3 ? errors.keyInvestorsImg3 : null,
                      }}
                      handleDeleteFile={() => {
                        setFieldValue('keyInvestorsImg3', '')
                        setImageUrl((state) => ({
                          ...state,
                          keyInvestorsImg3: '',
                        }))
                      }}
                      touched={{
                        keyInvestorsImg3: !!touched && !!touched.keyInvestorsImg1,
                      }}
                    />
                    <div className='key_investors_company_field'>
                      <Input
                        name='keyCompanyName'
                        labelText='Investor Name 3'
                        placeholder='Investor Name'
                        type='text'
                        required
                        value={values.keyInvestorsName3}
                        handleChange={(e) => setFieldValue('keyInvestorsName3', e.target.value)}
                        dirty={dirty}
                        errors={{
                          keyInvestorsName3:
                            !!errors && errors.keyInvestorsName3 ? errors.keyInvestorsName3 : null,
                        }}
                        touched={{
                          keyInvestorsName3: !!touched && !!touched.keyInvestorsName3,
                        }}
                      />
                    </div>
                  </Flex>
                  <Flex variant='column-center'>
                    <UploadedImage
                      published
                      fileUrl={imageUrl.keyInvestorsImg4 || values.keyInvestorsImg4}
                      id={'4'}
                    />
                    <InputFile
                      name='keyInvestorsImg4'
                      handleFiles={async (file: any) => {
                        if (handleFileUpload && file) {
                          const data = await handleFileUpload(file as File, 'image')
                          if (data && data.data) {
                            setFieldValue('keyInvestorsImg4', data.data.Key)
                            setImageUrl((state) => ({
                              ...state,
                              keyInvestorsImg4: data.data.url,
                            }))
                          } else handleErrorMessage && handleErrorMessage(data.message)
                        } else {
                          setFieldValue('keyInvestorsImg4', '')
                          setImageUrl((state) => ({
                            ...state,
                            keyInvestorsImg4: '',
                          }))
                        }
                      }}
                      label='Investor logo 4'
                      dirty={dirty}
                      errors={{
                        keyInvestorsImg4:
                          !!errors && errors.keyInvestorsImg4 ? errors.keyInvestorsImg4 : null,
                      }}
                      handleDeleteFile={() => {
                        setFieldValue('keyInvestorsImg4', '')
                        setImageUrl((state) => ({
                          ...state,
                          keyInvestorsImg4: '',
                        }))
                      }}
                      touched={{
                        keyInvestorsImg4: !!touched && !!touched.keyInvestorsImg4,
                      }}
                    />
                    <div className='key_investors_company_field'>
                      <Input
                        name='keyCompanyName'
                        labelText='Investor Name 4'
                        placeholder='Investor Name'
                        type='text'
                        required
                        value={values.keyInvestorsName4}
                        handleChange={(e) => setFieldValue('keyInvestorsName4', e.target.value)}
                        dirty={dirty}
                        errors={{
                          keyInvestorsName4:
                            !!errors && errors.keyInvestorsName4 ? errors.keyInvestorsName4 : null,
                        }}
                        touched={{
                          keyInvestorsName4: !!touched && !!touched.keyInvestorsName4,
                        }}
                      />
                    </div>
                  </Flex>
                </Flex>
                <Flex classes={['form-image-flex-direction']} variant='space-between'>
                  <Flex variant='column-center'>
                    <UploadedImage
                      published
                      fileUrl={imageUrl.keyInvestorsImg5 || values.keyInvestorsImg5}
                      id={'5'}
                    />
                    <InputFile
                      name='keyInvestorsImg5'
                      handleFiles={async (file: any) => {
                        if (handleFileUpload && file) {
                          const data = await handleFileUpload(file as File, 'image')
                          if (data && data.data) {
                            setFieldValue('keyInvestorsImg5', data.data.Key)
                            setImageUrl((state) => ({
                              ...state,
                              keyInvestorsImg5: data.data.url,
                            }))
                          } else handleErrorMessage && handleErrorMessage(data.message)
                        } else {
                          setFieldValue('keyInvestorsImg5', '')
                          setImageUrl((state) => ({
                            ...state,
                            keyInvestorsImg5: '',
                          }))
                        }
                      }}
                      label='Investor logo 5'
                      dirty={dirty}
                      errors={{
                        keyInvestorsImg5:
                          !!errors && errors.keyInvestorsImg5 ? errors.keyInvestorsImg5 : null,
                      }}
                      handleDeleteFile={() => {
                        setFieldValue('keyInvestorsImg5', '')
                        setImageUrl((state) => ({
                          ...state,
                          keyInvestorsImg5: '',
                        }))
                      }}
                      touched={{
                        keyInvestorsImg5: !!touched && !!touched.keyInvestorsImg5,
                      }}
                    />
                    <div className='key_investors_company_field'>
                      <Input
                        name='keyCompanyName'
                        labelText='Investor Name 5'
                        placeholder='Investor Name'
                        type='text'
                        required
                        value={values.keyInvestorsName5}
                        handleChange={(e) => setFieldValue('keyInvestorsName5', e.target.value)}
                        dirty={dirty}
                        errors={{
                          keyInvestorsName5:
                            !!errors && errors.keyInvestorsName5 ? errors.keyInvestorsName5 : null,
                        }}
                        touched={{
                          keyInvestorsName5: !!touched && !!touched.keyInvestorsName5,
                        }}
                      />
                    </div>
                  </Flex>
                </Flex>
                <Line />
                <Flex variant='flex-end'>
                  <div className='project-form-save-button-wrapper'>
                    <Flex>
                      <Button
                        classes={['auto-btn-width']}
                        variant={'primary-btn'}
                        text='Save'
                        handleClick={handleSubmit}
                      />
                    </Flex>
                    <div className='project-form-error-message-wrapper'>
                      {Object.entries(errors).map((data, index) => (
                        <span className='error-basic-label' key={index}>
                          {data[1]}
                        </span>
                      ))}
                    </div>
                  </div>
                </Flex>
              </form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export { HomeFormPresenter }
