import React from 'react'
import './project-detail-container.scss'
import { H2, Flex, List } from '../../lib/components'
import { DEAL_CONTOURS } from '../../lib/utils/message'
import { DealContoursProps } from '../../lib/types'

const DealContours = ({ dealContoursListData }: DealContoursProps) => {
  return (
    <Flex variant='column' classes={['mt-88', 'deal-contour-info']}>
      <H2 text={DEAL_CONTOURS} />
      <List classes={['mt-32', 'ml-12']} listData={dealContoursListData} />
    </Flex>
  )
}

export { DealContours }
