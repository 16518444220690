/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import './profile-detail.scss'
import {
  investorServices,
  kycServices,
  projectServices,
  investmentServices,
  userServices,
} from '../../services'
import { InvestorProps, InvestorKYCDocuments, InvestorData, Verification } from '../../lib/types'
import { RootState } from '../../store'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { showBanner } from '../../global-state/banner-slice'
import { kycMap } from './kyc-map'
import {
  getFileKeyFromSignedUrl,
  isValidFileSize,
  isValidImageType,
  isValidDocType,
} from '../../lib/utils/helpers'
import { ProfileDetailsPresenter } from './profile-details-presenter'
import { showModel } from '../../global-state/confirm-model-slice'

const ProfileDetailsContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { authToken } = useSelector((state: RootState) => state.gbcUser)
  const { isConfirm } = useSelector((state: RootState) => state.confirmModel)
  const [investorState, setInvestor] = useState<InvestorData>()
  const [pendingDocuments, setPendingDocuments] = useState<string[]>([])
  const { investorId } = useParams()
  const [lockStatus, setLockStatus] = useState('')
  const [approveKycData, setApproveKycData] = useState<any>({})
  const [investmentDetail, setInvestmentDetail] = useState<any>([])
  const [isUpdateKycStatus, setIsUpdateKycStatus] = useState<boolean>(false)
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (investorState) filterPendingDocuments(investorState)
  }, [investorState])

  const fetchData = async () => {
    const investorIdParam: number = investorId ? parseInt(investorId) : 0
    try {
      const kycDocumentsData = (await kycServices.getInvestorKYCDocuments(
        investorIdParam,
      )) as InvestorKYCDocuments
      const investorData = (await investorServices.getInvestorProfile(
        investorIdParam,
      )) as InvestorProps
      if (kycDocumentsData && investorData)
        setInvestor({
          investor: investorData,
          kycDocuments: kycDocumentsData,
        })
      const response = await investmentServices.getInvestmentDetailForInvestor(investorId as string)
      if (response && response.data && response.status === 200) {
        setInvestmentDetail(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    const lockUnlockHandler = async (investorId: number, status: string) => {
      const data: any = (await investorServices.lockUnlockAccount(investorId, {
        accountStatus: status === 'Active' ? 'Inactive' : 'Active',
      })) as InvestorProps
      if (data) {
        setInvestor((prevState: any) => ({
          ...prevState,
          investor: data,
        }))
        dispatch(
          showBanner({
            text: 'Investor account status updated',
            variant: 'success-banner',
          }),
        )
      }
    }

    if (isConfirm && investorId && lockStatus) lockUnlockHandler(parseInt(investorId), lockStatus)
  }, [isConfirm])

  useEffect(() => {
    const updateKyc = async (status: string) => {
      const data: any = await kycServices.approveKYC({
        userId: (investorState && investorState.investor && investorState.investor.userId) || 0,
        kycStatus: status,
      })
      if (data && data.status === 200) {
        setIsUpdateKycStatus(false)
      }
    }
    if (isUpdateKycStatus && investorState && investorState.kycDocuments) {
      const approvedDocuments = investorState.kycDocuments.documents.filter((obj: any) => {
        if (obj.status === 'APPROVED') return obj
      })
      const rejectedDocuments = investorState.kycDocuments.documents.filter((obj: any) => {
        if (obj.status === 'DECLINED') return obj
      })
      if (
        pendingDocuments.length === 0 &&
        investorState.kycDocuments.documents.length === approvedDocuments.length
      ) {
        updateKyc('APPROVED')
      }
      if (
        pendingDocuments.length === 0 &&
        investorState.kycDocuments.documents.length === rejectedDocuments.length
      ) {
        updateKyc('REJECTED')
      }
    }
  }, [
    isUpdateKycStatus &&
      investorState &&
      investorState.kycDocuments &&
      investorState.kycDocuments.documents,
  ])

  const lockUnlockHandler = async (investorId: number, status: 'Active' | 'Inactive') => {
    status === 'Active' &&
      dispatch(
        showModel({
          name: 'Investor Lock',
          message: 'Are you sure? You want to Lock Investor.',
        }),
      )
    status === 'Inactive' &&
      dispatch(
        showModel({
          name: 'Investor Unlock',
          message: 'Are you sure? You want to Unlock Investor.',
        }),
      )
    setLockStatus(status)
  }

  const changeHandler = (documentId: number, value: string, key: 'comment' | 'status') => {
    const newDocument = investorState?.kycDocuments.documents?.map((doc) => {
      if (doc.id === documentId) {
        return {
          ...doc,
          [key]: value,
        }
      }
      return doc
    })
    if (key === 'status' || (key === 'comment' && value.length < 200)) {
      setInvestor((prevState: any) => ({
        ...prevState,
        kycDocuments: {
          ...investorState?.kycDocuments,
          documents: newDocument,
        },
      }))
    }
  }

  const saveCommentOrApprove = async (
    documentId: number,
    value: string,
    key: 'comment' | 'status',
  ) => {
    const documentUpdateResult: any = await kycServices.commentOrApproveDocument(documentId, {
      [key]: value,
    })

    if (documentUpdateResult && documentUpdateResult.status === 200) {
      const documents = (investorState && investorState?.kycDocuments?.documents) ?? []

      const approvedDocuments = documents?.filter((doc) => {
        const document = doc.id === documentUpdateResult.data.id ? documentUpdateResult.data : doc

        return document.status === 'APPROVED'
      })

      // Updating user KYC status to pending state only if it's not in pending state
      if (
        approvedDocuments.length > 0 &&
        approvedDocuments.length < documents.length &&
        investorState?.investor?.kycStatus !== 'PENDING'
      ) {
        await kycServices.approveKYC({
          userId: (investorState && investorState.investor && investorState.investor.userId) || 0,
          kycStatus: 'PENDING',
        })
      }
    }
    if (documentUpdateResult && documentUpdateResult.status === 200) {
      const message = key === 'comment' ? 'Comment Added to file' : 'File status changed'
      dispatch(showBanner({ text: message, variant: 'success-banner' }))
      setIsUpdateKycStatus(true)
      fetchData()
    } else {
      const message =
        key === 'comment'
          ? 'Something went wrong while adding comment'
          : 'Something went wrong while updating status'
      dispatch(showBanner({ text: message, variant: 'error-banner' }))
    }
  }

  const approveKYC = async (userId: number, investorType: string) => {
    investorState &&
      (investorState.kycDocuments.kycStatus === 'REJECTED' ||
        investorState.kycDocuments.kycStatus === 'PENDING' ||
        investorState.kycDocuments.kycStatus === 'SENT_FOR_APPROVAL') &&
      dispatch(
        showModel({
          name: 'Approve Documents',
          message: 'Are you sure? You want to Approve all documents.',
        }),
      )
    investorState &&
      investorState.kycDocuments.kycStatus === 'APPROVED' &&
      dispatch(
        showModel({
          name: 'Reject Documents',
          message: 'Are you sure? You want to Reject all document.',
        }),
      )
    setApproveKycData({ userId: userId, investorType: investorType })
  }

  const filterPendingDocuments = (investorData: InvestorData) => {
    if (investorData.kycDocuments.investorType) {
      const allDocs = Object.keys(kycMap[investorData.kycDocuments.investorType])
      const kycDocNames = investorData.kycDocuments.documents.map((doc) => doc.name)
      const pendingDocs = allDocs.filter((name) => kycDocNames.indexOf(name) === -1)
      setPendingDocuments(pendingDocs)
    }
  }
  const handleFilePreview = async (fileKey: string): Promise<any> => {
    try {
      const signedUrl = getFileKeyFromSignedUrl(fileKey)
      const data: any = await projectServices.previewFile(signedUrl)
      if (data && data.data && data.status === 200) return data.data.url
      else {
        dispatch(
          showBanner({
            text: (data.data && data.data.message) || 'Something went wrong preview document',
            variant: 'error-banner',
          }),
        )
        return ''
      }
    } catch (error) {
      return ''
    }
  }

  const handleFileUpload = async (fileData: File, fileType: 'image' | 'doc') => {
    const isValidImage =
      fileType === 'image' ? isValidImageType(fileData) : isValidDocType(fileData)
    const isValidSize = isValidFileSize(fileData, fileType)
    if (isValidImage && isValidSize) {
      const data: any = await projectServices.postUploadFiles(fileData)
      if (data) return data
    } else {
      dispatch(
        showBanner({
          text: 'File size or type is incorrect for field',
          variant: 'error-banner',
        }),
      )
    }
  }

  const handleQuarterlyReport = async (file: string, name: string) => {
    if (investorId && file) {
      const payload = {
        investorId: parseInt(investorId),
        quater: name.replace('.pdf', ''),
        file: file,
      }
      const data: any = await investmentServices.uploadQuarterlyReportForInvestor(payload)
      if (data && data.data && data.status === 200) {
        dispatch(
          showBanner({
            text: 'Quarterly Report upload successfully.',
            variant: 'success-banner',
          }),
        )
      } else {
        dispatch(
          showBanner({
            text: (data.data && data.data.message) || 'Something went wrong preview document',
            variant: 'error-banner',
          }),
        )
      }
    }
  }
  const handleInvestmentLimit = async (investmentLimit: number) => {
    // converting dollar value in cents
    const investmentLimitInCents = investmentLimit * 100
    const data: any =
      investorId &&
      ((await investmentServices.setInvestmentLimit(
        parseInt(investorId),
        investmentLimitInCents,
      )) as InvestorProps)
    if (data && data.status === 200) {
      dispatch(
        showBanner({
          text: 'Investment limit updated',
          variant: 'success-banner',
        }),
      )
      fetchData()
    } else {
      dispatch(
        showBanner({
          text:
            (data.data && data.data.message) ||
            'Something went wrong while updating investment limit',
          variant: 'error-banner',
        }),
      )
    }
  }

  const handleSendVerificationEmailLink = async () => {
    const response: any =
      investorState?.investor?.email &&
      !investorState?.investor?.isEmailVerified &&
      (await userServices.sendVerificationLink(
        investorState.investor.email,
        Verification.verifyEmail,
      ))
    if (!!response && response.status === 200) {
      dispatch(
        showBanner({
          text: 'Email verification link has been sent to email successfully.',
          variant: 'success-banner',
        }),
      )
    } else {
      dispatch(
        showBanner({
          text:
            (response.data && response.data.message) || 'Something went wrong! While verify email',
          variant: 'error-banner',
        }),
      )
    }
  }

  return (
    <>
      {investorState && (
        <>
          <ProfileDetailsPresenter
            handleFilePreview={handleFilePreview}
            investorState={investorState}
            lockUnlockHandler={lockUnlockHandler}
            approveKYC={approveKYC}
            saveCommentOrApprove={saveCommentOrApprove}
            changeHandler={changeHandler}
            pendingDocuments={pendingDocuments}
            investmentDetail={investmentDetail}
            handleFileUpload={handleFileUpload}
            handleQuarterlyReport={handleQuarterlyReport}
            handleInvestmentLimit={handleInvestmentLimit}
            handleSendVerificationEmailLink={handleSendVerificationEmailLink}
          />
        </>
      )}
    </>
  )
}

export { ProfileDetailsContainer }
