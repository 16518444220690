import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch, bannerState } from '../lib/types'

const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    show: false,
    text: '',
    variant: undefined,
  },
  reducers: {
    showBanner: (state: bannerState, action: any) => {
      state.show = true
      state.text = action.payload.text
      state.variant = action.payload.variant || 'warning-banner'
    },
    hideBanner: (state: bannerState) => {
      state.show = false
      state.text = ''
      state.variant = undefined
    },
  },
})
export const showBannerTimeout = () => (dispatch: AppDispatch) => {
  setTimeout(() => {
    dispatch(hideBanner())
  }, 5000)
}
export { bannerSlice }
export const { showBanner, hideBanner } = bannerSlice.actions
