import { configureStore } from '@reduxjs/toolkit'
import { bannerSlice } from './global-state/banner-slice'
import { gbcUserSlice } from './global-state/gbc-user-slice'
import { confirmModelSlice } from './global-state/confirm-model-slice'
const store = configureStore({
  reducer: {
    gbcUser: gbcUserSlice.reducer,
    banner: bannerSlice.reducer,
    confirmModel: confirmModelSlice.reducer,
  },
})
export { store }
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
