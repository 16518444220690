/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import {
  H2,
  P,
  Button,
  Card,
  Span,
  LeftArrowIcon,
  Flex,
  Line,
  H4,
  H5,
  ProgressBar,
  InputFile,
  InputModal,
  Close,
  Input,
  EditIcon,
} from '../../lib/components'
import { InvestorKYC } from './investor-kyc'
import { useNavigate } from 'react-router-dom'
import { kycMap } from './kyc-map'
import {
  calculatePercentage,
  displayDollar,
  convertNumberWithCommas,
} from '../../lib/utils/helpers'

const ProfileDetailsPresenter = ({
  investorState,
  handleFilePreview,
  lockUnlockHandler,
  approveKYC,
  saveCommentOrApprove,
  changeHandler,
  pendingDocuments,
  investmentDetail,
  handleFileUpload,
  handleQuarterlyReport,
  handleInvestmentLimit,
  handleSendVerificationEmailLink,
}: any) => {
  const navigate = useNavigate()
  const [displayModal, setDisplayModal] = useState(false)
  const [displayModalInvestment, setDisplayModalInvestment] = useState(false)
  const [quarterlyFileValue, setQuarterlyFileValue] = useState('')
  const [quarterlyFileName, setQuarterlyFileName] = useState('')
  const [investmentLimitData, setInvestmentLimitData] = useState<string>('')
  useEffect(() => {
    setInvestmentLimitData(
      investmentDetail?.investmentLimit ? (investmentDetail?.investmentLimit / 100).toString() : '',
    )
  }, [investmentDetail])
  return (
    <>
      {investorState && (
        <>
          <Flex>
            <Button
              classes={['auto-small-btn-width']}
              variant='secondary-btn'
              IconComponent={<LeftArrowIcon />}
              handleClick={() => navigate('/investors')}
            />
            <Span classes={['ml-16', 'font-16']} variant='bold' text={'Back To Investors'} />
          </Flex>
          <Flex variant='space-between'>
            <H2 classes={['mb-48', 'mt-16']} text='Investor Profile' />
            {/* <Button
              classes={['mb-32', 'mt-16', 'auto-btn-width']}
              variant={
                investorState.investor.accountStatus === 'Active'
                  ? 'primary-btn'
                  : 'secondary-btn'
              }
              handleClick={() =>
                lockUnlockHandler(
                  investorState.investor.userId,
                  investorState.investor.accountStatus
                )
              }
              text={
                investorState.investor?.accountStatus === 'Active'
                  ? 'Lock'
                  : 'Unlock'
              }
            /> */}
          </Flex>
          <Flex variant='row' classes={['mb-24', 'investor-profile-card-container']}>
            <Card classes={['p-40', 'mr-16', 'mb-16']}>
              <P classes={['mb-8']} text='Investment Details' />
              {/* <Flex variant="space-between">
                <Span variant="bold" text="Investor Type" />
                <Span
                  variant="thin"
                  text={
                    investorState.kycDocuments?.investorType
                      ? investorState.kycDocuments?.investorType
                      : ''
                  }
                />
              </Flex> */}
              <Flex variant='space-between'>
                <Span variant='bold' text='Investment Limit' />
                <Span
                  variant='thin'
                  text={
                    investmentDetail?.investmentLimit || investmentDetail?.totalInvestment
                      ? `$${convertNumberWithCommas(
                          investmentDetail.totalInvestment / 100,
                        )}/$${convertNumberWithCommas(investmentDetail.investmentLimit / 100)}`
                      : '$0/$0'
                  }
                />
              </Flex>
              <ProgressBar
                variant='default-bar'
                maxValue={
                  investmentDetail.investmentLimit ? investmentDetail.investmentLimit : '100'
                }
                value={investmentDetail.totalInvestment ? investmentDetail.totalInvestment : '0'}
              />
              <Span
                variant='thin'
                text={
                  investmentDetail.totalInvestment &&
                  investmentDetail.investmentLimit &&
                  calculatePercentage(
                    investmentDetail.totalInvestment,
                    investmentDetail.investmentLimit,
                  ) > 90
                    ? 'Your investment limit is soon to be reached.'
                    : ''
                }
              />
            </Card>
            <Card classes={['p-40', 'mr-16', 'mb-16']}>
              <P classes={['mb-8']} text='General Details' />
              <Flex variant='space-between'>
                <Span variant='bold' text='Name' />
                <Span
                  variant='thin'
                  text={investorState.investor?.name ? investorState.investor.name : ''}
                />
              </Flex>
              <Flex variant='space-between'>
                <Span variant='bold' text='Email' />
                <Span
                  variant='thin'
                  text={investorState.investor?.email ? investorState.investor.email : ''}
                />
              </Flex>
              <Flex variant='space-between'>
                <Span variant='bold' text='Country of residence' />
                <Span
                  variant='thin'
                  text={
                    investorState.investor?.countryOfResidence
                      ? investorState.investor.countryOfResidence
                      : ''
                  }
                />
              </Flex>
              <Flex variant='space-between'>
                <Span variant='bold' text='Mobile phone' />
                <Span
                  variant='thin'
                  text={
                    investorState.investor?.phoneNumber
                      ? investorState.investor.phoneNumber.toString()
                      : ''
                  }
                />
              </Flex>
            </Card>
            {/* removed other details because in client app we removed investor detail tab from profile */}
            {/* <Card classes={['p-40', 'mb-16']}>
              <P classes={['mb-8']} text="Other Details" />
              <Flex variant="space-between">
                <Span variant="bold" text="Passport No.">
                  {' '}
                </Span>
                <Span
                  variant="thin"
                  text={
                    investorState.investor?.passportNumber
                      ? investorState.investor.passportNumber.toString()
                      : ''
                  }
                >
                  {' '}
                </Span>
              </Flex>
              <Flex variant="space-between">
                <Span variant="bold" text="Citizenship statement">
                  {' '}
                </Span>
                <Span
                  variant="thin"
                  text={
                    investorState.investor?.citizenship
                      ? investorState.investor.citizenship
                      : ''
                  }
                >
                  {' '}
                </Span>
              </Flex>
            </Card> */}
            {/* TO-DO Add Bank details here */}
            {/* <Card classes={['p-40', 'mb-16']}>
                    <P text='Bank Details' />
                    <Flex variant='space-between'>
                    </Flex>
                </Card> */}
          </Flex>
          <Flex classes={['upload-quarterly-report-container']}>
            <Card classes={['p-40', 'mb-16']}>
              <P classes={['mb-8']} text='Resend verification link to the Investor Email' />
              <Button
                classes={['auto-btn-width']}
                variant='primary-btn'
                handleClick={handleSendVerificationEmailLink}
                text='Resend verification link'
                isDisabled={investorState?.investor?.isEmailVerified ? true : false}
              />
              {investorState?.investor?.isEmailVerified ? (
                <div className='investment-success-sub-heading mt-16 mr-16'>
                  <p>{'The investor has verified the email.'}</p>
                </div>
              ) : (
                <div className='investment-warning-sub-heading mt-16 mr-16'>
                  <p>
                    {
                      'The investor has not verified the email. Use this button to resend the verification link to the investor email.'
                    }
                  </p>
                </div>
              )}
            </Card>
            <Card classes={['p-40', 'mb-16']}>
              <Flex variant='space-between'>
                <div>
                  <P classes={['mb-8']} text='Investment limit' />
                  {displayModalInvestment ? (
                    <InputModal>
                      <Flex variant='column' classes={['input-modal']}>
                        <Flex variant='space-between'>
                          <H4 text={'Investment Limit'} />
                          <Close height='24px' onClick={() => setDisplayModalInvestment(false)} />
                        </Flex>
                        <Line />
                        <Flex variant='column' classes={['reply-section']}>
                          <Input
                            name='investmentLimit'
                            placeholder='Investment Limit'
                            type='number'
                            required
                            value={investmentLimitData || ''}
                            handleChange={(e) => setInvestmentLimitData(e.target.value)}
                          />
                          <Button
                            variant='primary-btn'
                            text='Save'
                            handleClick={() => {
                              investmentLimitData && handleInvestmentLimit(investmentLimitData)
                              setDisplayModalInvestment(false)
                            }}
                          />
                        </Flex>
                      </Flex>
                    </InputModal>
                  ) : null}
                  <span>
                    {displayDollar(
                      investmentDetail.investmentLimit
                        ? investmentDetail.investmentLimit.toString()
                        : '',
                      'USD',
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <EditIcon
                      classes={['align-center', 'pointer']}
                      height='16px'
                      width='16px'
                      onClick={() => setDisplayModalInvestment(true)}
                    />
                  </span>
                  {((investmentDetail.length === 0 &&
                    investorState?.investor?.kycStatus === 'APPROVED') ||
                    (investmentDetail.kycStatus === 'APPROVED' &&
                      investmentDetail.totalInvestment &&
                      investmentDetail.investmentLimit &&
                      calculatePercentage(
                        investmentDetail.totalInvestment,
                        investmentDetail.investmentLimit,
                      ) > 90) ||
                    calculatePercentage(
                      investmentDetail.totalInvestment,
                      investmentDetail.investmentLimit,
                    ) < 0) && (
                    <div className='investment-warning-sub-heading mt-16 mr-16'>
                      <p>
                        {
                          'The investment amount is insufficient for investing in opportunity. Admin, please assign the investment limit for the investor.'
                        }
                      </p>
                    </div>
                  )}
                </div>
                {/* <div>
                  <P classes={['mb-8']} text="Quarterly Report" />
                  {displayModal ? (
                    <InputModal>
                      <Flex variant="column" classes={['input-modal']}>
                        <Flex variant="space-between">
                          <H4 text={'Quarterly Report'} />
                          <Close
                            height="24px"
                            onClick={() => {
                              setDisplayModal(false);
                              setQuarterlyFileValue('');
                              setQuarterlyFileName('');
                            }}
                          />
                        </Flex>
                        <Line />
                        <Flex variant="column" classes={['reply-section']}>
                          <InputFile
                            name={'quarterlyReport'}
                            type="doc"
                            handleFiles={async (file: any) => {
                              if (handleFileUpload && file) {
                                const data = await handleFileUpload(
                                  file as File,
                                  'doc'
                                );
                                if (data && data.data) {
                                  setQuarterlyFileValue(data.data.Key);
                                  setQuarterlyFileName(file && file.name);
                                }
                              }
                            }}
                            label=""
                          />
                          <Button
                            classes={['mt-24']}
                            variant="primary-btn"
                            text="Upload"
                            isDisabled={quarterlyFileValue ? false : true}
                            handleClick={() => {
                              handleQuarterlyReport(
                                quarterlyFileValue,
                                quarterlyFileName
                              );
                              setDisplayModal(false);
                              setQuarterlyFileValue('');
                              setQuarterlyFileName('');
                            }}
                          />
                        </Flex>
                      </Flex>
                    </InputModal>
                  ) : null}
                  <Button
                    classes={['auto-btn-width']}
                    variant="primary-btn"
                    handleClick={() => {
                      setDisplayModal(true);
                    }}
                    text={'Upload'}
                  />
                </div> */}
              </Flex>
            </Card>
          </Flex>
          <Line />

          <Flex variant='space-between'>
            <H4 classes={['mb-32']} text='Investor KYC' />
            {/* <Button
                    classes={['auto-btn-width']}
                    variant='primary-btn'
                    handleClick={() => {
                        approveKYC(investorState.investor.userId, investorState.kycDocuments.investorType ? investorState.kycDocuments.investorType : '')
                    }}
                    isDisabled={pendingDocuments && pendingDocuments?.length > 0 ? true : false}
                    text={investorState.kycDocuments.kycStatus === 'APPROVED' ? 'REJECT' : 'APPROVE' }
                    />  */}
          </Flex>

          <Flex variant='column'>
            <InvestorKYC
              saveCommentOrApprove={saveCommentOrApprove}
              changeHandler={changeHandler}
              documents={investorState.kycDocuments.documents}
              allDocuments={
                investorState.kycDocuments?.investorType
                  ? kycMap[investorState.kycDocuments?.investorType]
                  : kycMap.Individual
              }
              pendingDocuments={pendingDocuments}
              handleFilePreview={handleFilePreview}
            />
          </Flex>
        </>
      )}
    </>
  )
}

export { ProfileDetailsPresenter }
