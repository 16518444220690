import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OpportunityListPresenter } from './opportunity-list-presenter'
import { projectServices } from '../../services'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { SearchParams } from '../../lib/types'
import { showBanner } from '../../global-state/banner-slice'
import { showModel } from '../../global-state/confirm-model-slice'

function OpportunityListContainer() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isConfirm } = useSelector((state: RootState) => state.confirmModel)
  const [opportunities, setOpportunities] = useState([])
  const [searchParams, setSearchParams] = useState<SearchParams>({
    limit: '10',
    offset: '0',
  })
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    count: 5,
    offset: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [isCloseStatusToggle, setIsCloseStatusToggle] = useState(true)
  const [isDeleted, setIsDeleted] = useState(false)
  const [deleteId, setDeleteId] = useState(0)

  useEffect(() => {
    const fetchAllProjects = async () => {
      const data: any = await projectServices.getSearchOpportunities(searchParams)
      if (data.status === 200) {
        setOpportunities(data.opportunities)
        setPagination(data.pagination)
      }
    }
    fetchAllProjects()
    setIsDeleted(false)
  }, [currentPage, isDeleted, searchParams])

  useEffect(() => {
    const deleteProject = async () => {
      const data: any = await projectServices.deleteProject(deleteId)
      if (data.status === 200) {
        setIsDeleted(true)
        dispatch(
          showBanner({
            text: 'Opportunity deleted successfully',
            variant: 'success-banner',
          }),
        )
      } else {
        dispatch(
          showBanner({
            text: data.data && data.data.message,
            variant: 'error-banner',
          }),
        )
      }
    }
    if (isConfirm && deleteId) deleteProject()
  }, [isConfirm])

  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    const { limit, offset } = pagination
    setSearchParams({
      ...searchParams,
      offset: '0',
      limit: limit.toString(),
      [name]: value,
    })
    setCurrentPage(1)
    setPagination({ ...pagination, offset: 0 })
  }

  const resetSearchParams = async () => {
    setSearchParams({
      limit: '10',
      offset: '0',
      keyword: '',
      filterBy: '',
      sortBy: '',
    })
  }
  const handleCurrentPage = (currentPage: number) => {
    const { limit } = pagination
    setCurrentPage(currentPage)
    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })
    setPagination({ ...pagination, offset: (currentPage - 1) * limit })
  }

  const handleProjectClick = (projectSlug: string, id: number) => {
    navigate(`/form/${projectSlug}/${id}`)
  }
  const handleProjectDelete = async (id: number) => {
    dispatch(
      showModel({
        name: 'Opportunity Delete',
        message: 'Are you sure? You want to delete opportunity.',
      }),
    )
    setDeleteId(id)
  }

  const handleProjectInvestmentClick = (projectSlug: string, projectId: number) => {
    navigate(`/opportunity/investments/${projectSlug}/${projectId}`)
  }

  return (
    <OpportunityListPresenter
      pagination={pagination}
      handleCurrentPage={handleCurrentPage}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      resetSearchParams={resetSearchParams}
      searchParams={searchParams}
      handleSearchParams={handleSearchParams}
      opportunities={opportunities}
      handleProjectClick={handleProjectClick}
      handleProjectDelete={handleProjectDelete}
      handleProjectInvestmentClick={handleProjectInvestmentClick}
      isCloseStatusToggle={isCloseStatusToggle}
      setIsCloseStatusToggle={setIsCloseStatusToggle}
    />
  )
}

export { OpportunityListContainer }
