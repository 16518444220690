import React, { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FlexCenter } from '../../lib/components'
import { ResetPasswordPresenter } from './reset-password-presenter'
import { forgotPasswordSchema } from '../../lib/utils/schema'
import { userServices } from '../../services'
import { showBanner } from '../../global-state/banner-slice'

const initialValues = {
  password: '',
  passwordConfirmation: '',
}

const ResetPasswordContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const reRef = useRef<ReCAPTCHA>(null)
  const [searchParams] = useSearchParams()

  const handleSubmit = async (val: any) => {
    const token = await reRef.current?.executeAsync()
    reRef.current?.reset()

    const code = searchParams.get('code')
    const userId = searchParams.get('user')
    if (code) {
      const res: any = await userServices.resetPassword(
        {
          userId,
          code: code,
          password: val.password,
        },
        { 'g-recaptcha-response': token ? token : '' },
      )
      if (!!res && res.status === 200) {
        dispatch(
          showBanner({
            text: 'Password reset successfully',
            variant: 'success-banner',
          }),
        )
        navigate('/login')
      } else {
        dispatch(
          showBanner({
            text: (res.data && res.data.message) || 'Something went wrong! While reset password',
            variant: 'error-banner',
          }),
        )
      }
    }
  }

  return (
    <FlexCenter variant={'column-center'} classes={['mt-0 p-24']}>
      <ResetPasswordPresenter
        initialValues={initialValues}
        handleFormSubmit={handleSubmit}
        validationSchema={forgotPasswordSchema}
        reRef={reRef}
      />
    </FlexCenter>
  )
}

export { ResetPasswordContainer }
