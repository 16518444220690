import { axiosInstance } from '.'
import { InvestorKYCDocuments } from '../lib/types'
import {
  COMMENT_OR_APPROVE_KYC_DOCUMENT,
  GET_KYC_DOCUMENTS,
  GET_KYC_DOCUMENTS_V1,
  SET_INVESTMENT_LIMIT,
  UPDATE_KYC,
} from './endpoints'

export const kycServices = {
  setInvestmentLimit: async (investorId: number, details: { [key: string]: string | number }) => {
    try {
      const data: any = await axiosInstance.post(SET_INVESTMENT_LIMIT, details)
      return { ...data }
    } catch (error: any) {
      return error.response
    }
  },
  getInvestorKYCDocuments: async (investorId: number) => {
    try {
      // const data: any = await axiosInstance.get(GET_KYC_DOCUMENTS(investorId))
      const data: any = await axiosInstance.get(GET_KYC_DOCUMENTS_V1(investorId))
      const investorKYCData: InvestorKYCDocuments = data.data
      return investorKYCData
    } catch (error: any) {
      return error.response
    }
  },

  commentOrApproveDocument: async (
    documentId: number,
    details: { [key: string]: string | number },
  ) => {
    try {
      const data: any = await axiosInstance.post(
        COMMENT_OR_APPROVE_KYC_DOCUMENT(documentId),
        details,
      )
      return data
    } catch (error: any) {
      return error.response
    }
  },

  approveKYC: async (details: { [key: string]: string | number }) => {
    try {
      const data: any = await axiosInstance.post(UPDATE_KYC, details)
      return data
    } catch (error: any) {
      return error.response
    }
  },
}
