import React from 'react'
import {
  Button,
  Flex,
  H2,
  Input,
  Span,
  SelectDropdown,
  Line,
  EditIcon,
  Card,
  P,
} from '../../lib/components'
import { Pagination } from '../../lib/components/Pagination'
import './invesment-project-list.scss'
import { useBreakpoint } from '../../lib/utils/hooks'
import { SM } from '../../lib/utils/constants'
import { InvestmentPropsV1 } from '../../lib/types'
import { displayDollar, formatStatus } from '../../lib/utils/helpers'
import { investmentFilters, investmentSort } from '../../lib/utils/message'
import { useNavigate } from 'react-router-dom'

function InvestmentProjectListPresenter({
  user,
  investments,
  searchParams,
  handleSearchParams,
  resetSearchParams,
  pagination,
  handleCurrentPage,
  currentPage,
  handleFilePreview,
}: any) {
  const { breakpoint } = useBreakpoint()
  const navigate = useNavigate()

  return (
    <Flex variant='column'>
      <H2 text='Investments' classes={['m-48 align-center']} />

      {user && (
        <Card classes={['p-40', 'mb-48']}>
          <P text='User Details' />
          <Flex variant='space-between'>
            <Span variant='bold' text='Name' />
            <Span variant='thin' text={user.name} />
          </Flex>
          <Flex variant='space-between'>
            <Span variant='bold' text='Email' />
            <Span variant='thin' text={user.email} />
          </Flex>
        </Card>
      )}

      <Flex variant='column-center'>
        <div className='toolbar-layout gap-12 mb-32'>
          <Input
            name='keyword'
            value={searchParams.keyword}
            handleChange={(e) => handleSearchParams({ name: e.target.name, value: e.target.value })}
            placeholder='Search'
          />
          <SelectDropdown
            name='filterBy'
            id='filterId'
            formId=''
            list={investmentFilters}
            value={searchParams.filterBy}
            placeholder={'Filter'}
            handleChange={(val) => handleSearchParams({ name: 'filterBy', value: val })}
          />
          <div className='sort-reset'>
            <SelectDropdown
              name='sortBy'
              id='sortById'
              formId=''
              list={investmentSort}
              value={searchParams.sortBy}
              placeholder={'Sort'}
              handleChange={(val) => handleSearchParams({ name: 'sortBy', value: val })}
            />
            <Button
              handleClick={() => resetSearchParams()}
              classes={['reset-btn']}
              variant='secondary-btn'
              text='Reset'
            />
          </div>
        </div>
        <Line />
        <div className='w-100'>
          {investments && investments.length > 0 ? (
            <Flex variant='row' classes={['mb-24', 'table_scroll_horizontal']}>
              <table className={'styled-table'}>
                <thead>
                  <tr>
                    <th>Opportunity Name</th>
                    <th>Investor Email</th>
                    <th>Investment Status</th>
                    <th>Investment Amount</th>
                    <th>Ticket Size</th>
                    <th>Closing Date</th>
                    <th>Investment Approval</th>
                  </tr>
                </thead>
                <tbody>
                  {investments.map(
                    ({
                      id,
                      opportunity_slug,
                      opportunity_id,
                      investor_id,
                      name,
                      asset_class,
                      status,
                      closing_date,
                      amount,
                      ticket_size,
                      currency_code,
                    }: InvestmentPropsV1) => {
                      return (
                        <tr key={id}>
                          <td>{name}</td>
                          <td>{asset_class}</td>
                          <td>{formatStatus(status)}</td>
                          <td>{displayDollar(amount.toString(), currency_code)}</td>
                          <td>{displayDollar(ticket_size.toString(), currency_code)}</td>
                          <td>{new Date(closing_date).toDateString()}</td>
                          <td>
                            <EditIcon
                              classes={['align-center', 'pointer']}
                              height='24px'
                              width='24px'
                              onClick={async () => {
                                navigate(
                                  `/opportunity/${opportunity_slug}/${opportunity_id}/investor/${investor_id}/investment/detail/${id}`,
                                )
                              }}
                            />
                          </td>
                        </tr>
                      )
                    },
                  )}
                </tbody>
              </table>
            </Flex>
          ) : (
            <Flex classes={['column-center']}>
              <Span variant={'large'} text='No Investments Found' />
              <Span variant={'thin'} text='Try resetting the filters and try again' />
            </Flex>
          )}
        </div>
        {breakpoint && breakpoint === SM
          ? null
          : investments &&
            investments.length > 0 && (
              <Pagination
                currentPage={currentPage}
                handleCurrentPage={handleCurrentPage}
                pagination={pagination}
              />
            )}
      </Flex>
    </Flex>
  )
}

export { InvestmentProjectListPresenter }
