import React from 'react'
import { FlexCenter } from '../../lib/components'
import { ForgotPasswordPresenter } from './forgot-password-presenter'
import { emailInputSchema } from '../../lib/utils/schema'
import { userServices } from '../../services'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showBanner } from '../../global-state/banner-slice'
import { Verification } from '../../lib/types'

const initialValues = {
  email: '',
}

const ForgotPasswordContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = async (val: any) => {
    const response: any = await userServices.sendVerificationLink(
      val.email,
      Verification.resetPassword,
    )
    if (!!response && response.status === 200) {
      dispatch(
        showBanner({
          text: 'Instruction to reset password has been sent to your email.',
          variant: 'success-banner',
        }),
      )
      navigate('/reset-instructions')
    } else {
      dispatch(
        showBanner({
          text:
            (response.data && response.data.message) || 'Something went wrong! While verify email',
          variant: 'error-banner',
        }),
      )
    }
  }

  return (
    <FlexCenter variant={'column-center'} classes={['mt-0']}>
      <ForgotPasswordPresenter
        initialValues={initialValues}
        handleFormSubmit={handleSubmit}
        validationSchema={emailInputSchema}
      />
    </FlexCenter>
  )
}

export { ForgotPasswordContainer }
