/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import {
  Button,
  Flex,
  H2,
  Input,
  Span,
  SelectDropdown,
  Line,
  EyeIcon,
  Card,
  P,
  EditIcon,
} from '../../lib/components'
import { Pagination } from '../../lib/components/Pagination'
import './investment-user-list.scss'
import { useBreakpoint } from '../../lib/utils/hooks'
import { SM, LG } from '../../lib/utils/constants'
import { InvestmentPropsV1, Jobs } from '../../lib/types'
import { displayDollar, formatPercentage, formatStatus } from '../../lib/utils/helpers'
import { investmentFilters, investmentSort } from '../../lib/utils/message'
import { useNavigate } from 'react-router-dom'

function InvestmentUserListPresenter({
  project,
  investments,
  searchParams,
  handleSearchParams,
  resetSearchParams,
  handleInvestorClick,
  pagination,
  handleCurrentPage,
  currentPage,
  handleFilePreview,
  jobs,
}: any) {
  const { breakpoint } = useBreakpoint()
  const navigate = useNavigate()
  return (
    <Flex variant='column'>
      <H2
        text={`Investments for ${project && project.opportunity_title}`}
        classes={['m-48 align-center']}
      />

      <Flex variant={breakpoint && breakpoint > LG ? 'row' : 'column'} classes={['gap-32']}>
        {project && (
          <Card classes={['p-40', 'mb-48']}>
            <P text='Opportunity Details' />
            <Flex variant='space-between'>
              <Span variant='bold' text='Opportunity Name' />
              <Span variant='thin' text={project.opportunity_title} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Asset class' />
              <Span variant='thin' text={project.asset_class} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Ticket Size' />
              <Span
                variant='thin'
                text={displayDollar(project.ticket_size, project.currency_code)}
              />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='IRR' />
              <Span variant='thin' text={formatPercentage(project.investment_return)} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Raise Goal' />
              <Span
                variant='thin'
                text={displayDollar(project.raise_goal, project.currency_code)}
              />
            </Flex>
          </Card>
        )}

        {project && (
          <Card classes={['p-40', 'mb-48']}>
            <P text='Investment Metrics' />
            <Flex variant='space-between'>
              <Span variant='bold' text='Total number of approved  investment' />
              <Span variant='thin' text={project.no_of_investors} />
            </Flex>
            <Flex variant='space-between'>
              <Span variant='bold' text='Total amount raised and approved' />
              <Span
                variant='thin'
                text={displayDollar(project.committedAmount, project.currencyCode)}
              />
            </Flex>
          </Card>
        )}
      </Flex>

      <Flex variant='column-center'>
        <div className='toolbar-layout gap-12 mb-32'>
          <Input
            name='keyword'
            value={searchParams.keyword}
            handleChange={(e) => handleSearchParams({ name: e.target.name, value: e.target.value })}
            placeholder='Search'
          />
          <SelectDropdown
            name='filterBy'
            id='filterId'
            formId=''
            list={investmentFilters}
            value={searchParams.filterBy}
            placeholder={'Filter'}
            handleChange={(val) => handleSearchParams({ name: 'filterBy', value: val })}
          />
          <div className='sort-reset'>
            <SelectDropdown
              name='sortBy'
              id='sortById'
              formId=''
              list={investmentSort}
              value={searchParams.sortBy}
              placeholder={'Sort'}
              handleChange={(val) => handleSearchParams({ name: 'sortBy', value: val })}
            />
            <Button
              handleClick={() => resetSearchParams()}
              classes={['reset-btn']}
              variant='secondary-btn'
              text='Reset'
            />
          </div>
        </div>
        <Line />
        <div className='w-100'>
          {investments && investments.length > 0 ? (
            <Flex variant='row' classes={['mb-24', 'table_scroll_horizontal']}>
              <table className={'styled-table'}>
                <thead>
                  <tr>
                    <th>Investor Name</th>
                    <th>Investor Email</th>
                    <th>Investment Status</th>
                    <th>Investment Amount</th>
                    <th>Investment Date</th>
                    <th>Manage Investment</th>
                    <th>Investor Profile</th>
                  </tr>
                </thead>
                <tbody>
                  {investments.map(
                    ({
                      id,
                      investor_id,
                      name,
                      email,
                      status,
                      amount,
                      opportunity_id,
                      opportunity_slug,
                      created_at,
                      currency_code,
                    }: InvestmentPropsV1) => {
                      return (
                        <tr key={id}>
                          <td>{name}</td>
                          <td>{email}</td>
                          <td>{formatStatus(status)}</td>
                          <td>{displayDollar(amount.toString(), currency_code)}</td>
                          <td>{new Date(created_at as any).toDateString()}</td>
                          <td>
                            <EditIcon
                              classes={['align-center', 'pointer']}
                              height='24px'
                              width='24px'
                              onClick={async () =>
                                navigate(
                                  `/opportunity/${opportunity_slug}/${opportunity_id}/investor/${investor_id}/investment/detail/${id}`,
                                )
                              }
                            />
                          </td>
                          <td>
                            <EyeIcon
                              classes={['align-center', 'pointer']}
                              height='24px'
                              width='24px'
                              onClick={() => {
                                handleInvestorClick(investor_id)
                              }}
                            />
                          </td>
                        </tr>
                      )
                    },
                  )}
                </tbody>
              </table>
            </Flex>
          ) : (
            <Flex classes={['column-center']}>
              <Span variant={'large'} text='No Investments Found' />
              <Span variant={'thin'} text='Try resetting the filters and try again' />
            </Flex>
          )}
        </div>
        {breakpoint && breakpoint === SM
          ? null
          : investments &&
            investments.length > 0 && (
              <Pagination
                currentPage={currentPage}
                handleCurrentPage={handleCurrentPage}
                pagination={pagination}
              />
            )}
      </Flex>

      <Flex variant='column' classes={['mt-88']}>
        <H2 text='Recent bulk documents upload status' />
        <Flex classes={['column']}>
          <Span
            classes={['mt-8']}
            text='The tables show the documents which are could not be uploaded  and reason of the error in the format or content'
          />
        </Flex>
        <div className='w-100'>
          {jobs && jobs.length > 0 && (
            <Flex variant='row' classes={['mb-24', 'table_scroll_horizontal']}>
              <table className={'styled-table'}>
                <thead>
                  <tr>
                    <th>Investor email in the document</th>
                    <th>Uploaded at</th>
                    <th>Reason of failure</th>
                    <th>Failed fund allocation document</th>
                  </tr>
                </thead>
                <tbody>
                  {jobs.map((job: Jobs, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{job.email}</td>
                        <td>{new Date(job.createdAt).toLocaleString()}</td>
                        <td>{job.status}</td>
                        <td>
                          <EyeIcon
                            classes={['align-center', 'pointer']}
                            height='24px'
                            width='24px'
                            onClick={async () => {
                              const url = await handleFilePreview(job.fileId)
                              window.open(url, '_blank')
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Flex>
          )}
        </div>
      </Flex>
    </Flex>
  )
}

export { InvestmentUserListPresenter }
