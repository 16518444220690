import React from 'react'
import './project-detail-container.scss'
import { H2, Flex, P, Image } from '../../lib/components'
import { ABOUT_THE_COMPANY } from '../../lib/utils/message'
import { AboutCompanyProps } from '../../lib/types'

const AboutCompany = ({ aboutCompany }: AboutCompanyProps) => {
  return (
    <Flex variant='column'>
      <H2 text={ABOUT_THE_COMPANY} />
      <P text={aboutCompany} classes={['mt-32']} textSize='regular' variant='default-gray' />
    </Flex>
  )
}

export { AboutCompany }
