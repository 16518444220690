import React, { useRef } from 'react'
import { LoginPresenter } from './login-presenter'
import { signInSchema as loginSchema } from '../../lib/utils/schema'
import { Card, H2, Flex } from '../../lib/components'
import { userServices } from '../../services'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch } from 'react-redux'
import { createConfiguration } from '../../global-state/gbc-user-slice'
import { showBanner } from '../../global-state/banner-slice'
import { useNavigate } from 'react-router-dom'

const initialValues = {
  email: '',
  password: '',
  rememberMe: '',
}

const LoginContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const reRef = useRef<ReCAPTCHA>(null)

  const handleSubmit = async (val: any) => {
    const token = await reRef.current?.executeAsync()
    reRef.current?.reset()

    const user: any = await userServices.userLogin(
      {
        email: val.email?.toLowerCase(),
        password: val.password,
      },
      { 'g-recaptcha-response': token ? token : '' },
    )
    if (!!user && user.status === 200 && user.data.role !== 'INVESTOR') {
      dispatch(createConfiguration(user.data))
      dispatch(showBanner({ text: 'Login Success', variant: 'success-banner' }))
      navigate('/')
    } else {
      dispatch(
        showBanner({
          text: (user.data && user.data.message) || 'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
    }
  }

  return (
    <Flex variant='column-center'>
      <H2 text={'Log In'} classes={['mb-48']} />
      <Card classes={['p-40']}>
        <LoginPresenter
          initialValues={initialValues}
          validationSchema={loginSchema}
          handleFormSubmit={handleSubmit}
          reRef={reRef}
        />
      </Card>
    </Flex>
  )
}

export { LoginContainer }
